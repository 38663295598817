import { useEffect, useMemo } from 'react';
import { useChatContext, useChatInputContext, useChatSocket } from 'shared/contexts';
import { storage } from 'shared/services';
import { useAppState } from 'shared/state';
import { useUpdateEntities } from '../../useUpdateEntities';
import { useDidMountEffect } from '../../useDidMountEffect';
import { useGetEntityOne } from '../../useGetEntityOne';
import { useGetEntity } from '../../useGetEntity';
import { useQueryParams } from '../../useQueryParams';
import { useAxios } from '../../useAxios';
import { Case, IMessage } from '../../../interfaces';
import { useChatMessage } from '../useChatMessage';
import { useUserSocket } from '../../../userSocket';
import { useDraftFunctions } from './useDraftFunctions';
import { TSaveEntityDraftPayload } from './draftHooks.types';
import { EntityDraft } from 'pages/GroupChat/types';
import { useSelector } from 'react-redux';
import { RootState } from 'store/root.state';

type LS = {
	payload: TSaveEntityDraftPayload;
	isSaved?: boolean;
};
const SHOW_LOGS = false;
export function useDraftMessage() {
	const { entityType, entityId } = useChatContext();
	const {
		activeDraft,
		setActiveDraft,
		searchQuery,
		groupSearchQuery,
		companyId,
		caseType,
		selectedCaseFilter
	} = useAppState();
	const { message, reply, editingMessage, setMessage, setEditingMessage, setReply } =
		useChatInputContext();
	const { sendJsonMessage } = useChatSocket();
	const { sendJsonMessage: sendJsonMessageViaUserSocket } = useUserSocket();
	const { query } = useQueryParams();
	const { messages } = useChatMessage({ sendJsonMessage, entityId, entityType });
	const { getLS, updateEntityDraft, removeLS, updateLS } = useDraftFunctions();
	const entityData = useSelector(
		(state: RootState) => state?.entities?.[entityType === 'case' ? 'cases' : 'ims-chats']
	);

	//const parsedSelectedCaseFromLS = JSON.parse(storage.get('selectedCaseFilter') || '{}')[caseType];
	//const selectedFilterTemplate = selectedCaseFilter[caseType] || parsedSelectedCaseFromLS;

	const queryEntityId = query?.chat || query?.case;

	//TODO: remove below later
	/* const casesEntityName = `AllBusinessCases-${query?.status}-${caseType}-${
		searchQuery.length
			? 'search'
			: `${selectedFilterTemplate ? selectedFilterTemplate.id : 'normal'}`
	}`;

	const groupEntityName = `AllImsChats-${companyId}-${groupSearchQuery}`;
	const entityName = entityType === 'case' ? casesEntityName : groupEntityName;

	const { all: allCaseChatItems } = useGetEntity({
		entity: entityType === 'case' ? 'cases' : 'ims-chats',
		entityId,
		entityName
	});
	const caseChatEntity = useGetEntityOne({
		entity: entityType === 'case' ? 'cases' : 'ims-chats',
		entityId,
		entityName: `${entityType === 'case' ? 'Case' : 'Group'}-${companyId}-${entityId}One`
	}); */

	const {
		response: editingMsg,
		fetchData: fetchEditingMessage,
		loading: editLoading
	} = useAxios({
		url: '',
		dynamicUrl: true,
		cb: {
			success: (data, args) => {
				setEditingMsg(data);
			},
			error: (error, args) => {}
		}
	});

	const {
		response: repliedMsg,
		fetchData: fetchRepliedMessage,
		loading: replyLoading
	} = useAxios({
		url: '',
		dynamicUrl: true,
		cb: {
			success: (data, args) => {
				setRepliedMsg(data);
			},
			error: (error, args) => {}
		}
	});

	const entityDraftObjNotCached = getLS();

	const entityDraftObj = useMemo(() => entityDraftObjNotCached, [entityDraftObjNotCached]);

	//Close edited message or replied message
	useEffect(() => {
		if (queryEntityId) {
			setEditingMessage(null);
			setReply(null);
		}
	}, [queryEntityId]);

	//Set message in input if there is draft message
	useEffect(() => {
		const entityLS = getLS();
		// console.log('entityLS', { ...entityLS });
		const areMessagesLoaded = messages.items.length;
		if (entityId in entityLS && activeDraft) {
			const draftMessage = entityLS[entityId];
			if (process.env.NODE_ENV !== 'production' && SHOW_LOGS) {
				console.log('the draft is in LS', { draftMessage, entityId });
				console.log('activeDraft', activeDraft);
			}
			const { editing_id, reply_to, isSaved } = draftMessage;
			// if (!message.length)
			if (!editingMessage) {
				setMessage(draftMessage.text);
			}
			// if (activeDraft) {
			if (areMessagesLoaded) {
				handleEditingReplyDraft({
					editing_id,
					reply_to,
					isSaved,
					messages,
					activeEntityDraft: activeDraft
				});
			}
			// }
			return;
		}

		if (!(entityId in entityLS && entityLS[entityId]?.text) && !editingMessage) {
			setMessage('');
		}
	}, [entityId, editingMessage, reply, activeDraft, messages]);

	//Set Draft message from REST API and between case switches
	useEffect(() => {
		const entityLS = getLS();

		if (process.env.NODE_ENV !== 'production' && SHOW_LOGS) {
			console.log('entityLS', { entityLS, entityId });
		}
		if (!(entityId in entityLS) && !searchQuery && !groupSearchQuery && activeDraft) {
			if (process.env.NODE_ENV !== 'production' && SHOW_LOGS) {
				console.log('draft is not in LS, activeDraft', activeDraft);
			}
			const areMessagesLoaded = messages.items.length;
			if (activeDraft && areMessagesLoaded) {
				const { editing_id, reply_to } = activeDraft;

				const draftText = activeDraft.text;
				setMessage(draftText);

				if (activeDraft) {
					handleEditingReplyDraft({
						editing_id,
						reply_to,
						isSaved: !!draftText,
						messages,
						activeEntityDraft: activeDraft
					});
				}

				return;
			}

			// if (!editingMessage) setMessage('');
		}
	}, [entityId, /*allCaseChatItems,*/ reply, activeDraft, messages]);

	type EditingReplyFnTypes = {
		editing_id: number | null;
		reply_to: number | null;
		isSaved: boolean;
		messages: any;
		activeEntityDraft: EntityDraft;
	};

	function handleEditingReplyDraft({
		editing_id,
		reply_to,
		isSaved,
		messages,
		activeEntityDraft
	}: EditingReplyFnTypes) {
		const referringMsgId = editing_id || reply_to;
		if (process.env.NODE_ENV !== 'production' && SHOW_LOGS) {
			console.log('repl func 0', {
				reply,
				editingMessage,
				editing_id,
				reply_to,
				referringMsgId,
				isSaved
			});
		}

		if (!reply && !editingMessage && referringMsgId && isSaved) {
			const referencedMessage = messages.items.find(
				(message: IMessage) =>
					message.id === referringMsgId || message.message_id === referringMsgId
			);

			if (editing_id && referencedMessage) {
				if (process.env.NODE_ENV !== 'production') {
					console.log('repl func edit 0');
				}

				setEditingMsg({ ...referencedMessage, text: activeEntityDraft?.text });
			}
			if (editing_id && !referencedMessage && !editLoading) {
				if (process.env.NODE_ENV !== 'production' && SHOW_LOGS) {
					console.log('repl func edit 1', editLoading);
				}

				fetchEditingMessage({ url: `/messages/${referringMsgId}/` });
			}

			if (reply_to && referencedMessage) {
				if (process.env.NODE_ENV !== 'production' && SHOW_LOGS) {
					console.log('repl func reply  0');
				}
				setRepliedMsg(referencedMessage);
			}

			if (reply_to && !referencedMessage && !replyLoading) {
				if (process.env.NODE_ENV !== 'production' && SHOW_LOGS) {
					console.log('repl func reply  1', replyLoading);
				}
				fetchRepliedMessage({ url: `/messages/${referringMsgId}/` });
			}
		}
	}

	function setEditingMsg(data: IMessage) {
		setEditingMessage({
			id: (data.id || data.message_id)!,
			text: data.text,
			custom_uuid: data.custom_uuid,
			fileType: data.file_type,
			replied_to_id: null
		});
	}
	function setRepliedMsg(data: IMessage) {
		setReply({
			id: (data.id || data.message_id)!,
			text: data.text,
			file_type: data.file_type,
			from_user_first_name: data.from_user?.first_name,
			from_user_last_name: data.from_user?.last_name
		});
	}

	//add and update draft message in LS when we type message
	useDidMountEffect(() => {
		// setLS({ value: message });
	}, [message]);

	//Delete draft from LS if message has been deleted or sent
	useEffect(() => {
		const LsDraftObject = getLS();
		const draftMessage = LsDraftObject?.[entityId]?.text || '';
		if (entityId in LsDraftObject && draftMessage.length === 0 && activeDraft) {
			if (process.env.NODE_ENV !== 'production' && SHOW_LOGS) {
				console.log('removing draft in useEffect out removeLS');
			}

			updateEntityDraft('reset');
			if (activeDraft) setActiveDraft(null);
			removeLS(entityId);
			sendDeleteDraft();
		}
	}, [entityId, activeDraft, message]);

	// function getLS(entityIdArg?: number): { [key: string]: any } {
	// 	const entity = JSON.parse(storage.get(`${entityType}Draft`) || '{}');
	// 	if (entityIdArg) {
	// 		if (entityIdArg in entity) return entity[entityIdArg];
	// 	}
	// 	return entity;
	// }

	function setLS({ payload, isSaved = false }: LS) {
		const entityName = `${entityType}Draft`;
		const { message, editingMessage, reply, entityId } = payload;

		if (message !== undefined) {
			const draftObject = JSON.parse(storage.get(entityName)!);

			const entityIdDraftData = {
				isSaved: !!(entityData?.[entityId] as Case)?.draft_message || isSaved,
				text: message,
				reply_to: editingMessage // if there is editing msg we must reset reply_to to null
					? null
					: reply
					? reply.id
					: draftObject?.[entityId]?.reply_to
					? draftObject?.[entityId]?.reply_to
					: null,
				editing_id: reply // if there is replied msg we must reset editing_id to null
					? null
					: editingMessage
					? editingMessage.id
					: draftObject?.[entityId]?.editing_id
					? draftObject?.[entityId]?.editing_id
					: null
			};

			if (storage.get(entityName)) {
				const finalData = {
					...draftObject,
					[entityId]: entityIdDraftData
				};
				storage.set(entityName, JSON.stringify(finalData));
				return;
			}

			const finalData = { [entityId]: entityIdDraftData };

			storage.set(entityName, JSON.stringify(finalData));
		}
	}

	// function removeLS(entityIdArg: number = entityId, callback?: () => void) {
	// 	const entity = { ...getLS() };
	// 	if (entityIdArg in entity) delete entity[entityIdArg];
	// 	if (process.env.NODE_ENV !== 'production' && SHOW_LOGS) {
	// 		console.log('removing draft', entity);
	// 	}
	//
	// 	storage.set(`${entityType}Draft`, JSON.stringify(entity));
	// 	if (typeof callback === 'function') setTimeout(callback, 0);
	// }

	function sendDraftViaSocket(message: string, type: string) {
		const draftPayload = {
			entity_id: entityId,
			editing_id: editingMessage ? editingMessage.id : null,
			entity_type: entityType,
			reply_to_id: reply ? reply.id : null,
			text: message,
			type: 'draft_message'
		};
		//Just for sending draft when we click "close chat" button
		if (type === 'user' && message.length) {
			sendJsonMessageViaUserSocket(draftPayload);
			return;
		}

		if (message.length) sendJsonMessage(draftPayload);
	}

	function sendDeleteDraft() {
		const payload = {
			entity_id: entityId,
			type: 'delete_draft_message'
		};

		sendJsonMessage(payload);
	}

	function saveDraft(type = 'chat', payload: TSaveEntityDraftPayload) {
		/*
		1) send via socket
		2) Update case item or chat item draft_message field if not in search mode
		* */
		if (process.env.NODE_ENV !== 'production' && SHOW_LOGS) {
			console.log('draft has been saved');
		}

		const { message } = payload;

		if (!message?.length) return;

		sendDraftViaSocket(message, type);
		setLS({ payload, isSaved: true });

		if (!searchQuery && !groupSearchQuery) {
			updateEntityDraft('add', payload);
		}
	}

	// function updateEntityDraft(type: 'add' | 'reset') {
	// 	let draftMessage = null;
	//
	// 	if (type === 'add') {
	// 		draftMessage = {
	// 			editing_id: editingMessage ? editingMessage.id : null,
	// 			id: entityId,
	// 			reply_to: reply && reply.id ? reply.id : null,
	// 			text: message
	// 		};
	//
	// 		// setActiveDraft(draftMessage);
	// 	}
	//
	// 	updateEntities({
	// 		entity: entityType === 'case' ? 'cases' : 'ims-chats',
	// 		entityId,
	// 		updatingData: {
	// 			draft_message: draftMessage
	// 		}
	// 	});
	// }

	return {
		saveDraft,
		getLS,
		updateEntityDraft,
		removeLS,
		sendDeleteDraft,
		setLS,
		updateLS
	};
}

import axios from 'axios';
import * as Sentry from '@sentry/browser';
import config from 'config';
import storage from '../storage';
import get from 'lodash/get';
import { history } from 'store';
// import localforage from 'localforage';

const getLanguage = () => {
	return (storage.get('i18nextLng') ?? 'en')?.toLowerCase();
};

const request = axios.create({
	baseURL: config.API_ROOT
});

request.defaults.params = {};
request.defaults.headers.common['Accept'] = 'application/json';
// request.defaults.headers.common['Cache-Control'] = 'no-cache';
request.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
request.defaults.headers['platform'] = 'web';
request.defaults.headers['app'] = 'business';
request.defaults.headers['Accept-Language'] = getLanguage();

const subscribe = (store: any): any => {
	let token = storage.get('token');

	const state = store.getState();
	// request.defaults.params['_l'] = state.system.currentLangCode;
	if (state?.auth?.token) token = state?.auth?.token;

	if (token) {
		request.defaults.headers.common['Authorization'] = `Token ${token}`;
	}
};

/**
 * API Sentry Error Tracker
 */
request.interceptors.response.use(
	(response) => response,
	(error) => {
		const expectedError =
			error.response && error.response.status >= 400 && error.response.status < 500;

		const unAuthorizedError = error.response && error.response.status === 401;
		const token = storage.get('token');
		if (unAuthorizedError && token) {
			history.push('/logout?resignin=true');
			localStorage.clear();
			delete request.defaults.headers.common['Authorization'];
		}

		if (!expectedError) {
			Sentry.withScope((scope) => {
				scope.setExtra('Error URL', get(error, 'config.url'));
				scope.setExtra('Error Message', get(error, 'response.data.message'));
				scope.setExtra(
					'Error Status',
					`${get(error, 'response.status')} - ${get(error, 'response.statusText')}`
				);
				scope.setExtra('Error Full', error);
				Sentry.captureException(error);
			});
		}

		return Promise.reject(error);
	}
);

export default {
	request,
	subscribe
};

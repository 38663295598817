import { useAxios, useNotistack, useUpdateEntities } from 'shared/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppState } from 'shared/state';
import { useChatContext } from '../../../contexts';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { useTranslation } from 'react-i18next';

type Message = {
	text: string;
	translated_text?: string | null;
	original_text?: string | null;
	custom_uuid: string;
	id?: number;
	message_id?: number;
	is_translation_shown?: boolean;
};

type UpdatedField = {
	text: string;
	original_text?: string | null;
	translated_text?: string | null;
	is_translation_shown: boolean;
};

export function useMessageTranslate(message: Message) {
	const { companyId } = useAppState();
	const { updateEntities } = useUpdateEntities();
	const { showNotification } = useNotistack();
	const { entityType } = useChatContext();
	const { userData } = useAuth();
	const cachedTranslationLanguageCode = useMemo(
		() => userData?.content_translation_language?.id,
		[]
	);
	const { t } = useTranslation();

	const {
		text,
		translated_text,
		original_text,
		custom_uuid,
		message_id,
		id,
		is_translation_shown
	} = message;

	const [isTranslationFetched, setIsTranslationFetched] = useState(true);

	const { fetchData } = useAxios({
		url: `/${companyId}/translation/`,
		method: 'post',
		dynamicValues: true,
		cb: {
			success: (data) => {
				updateMessageText({
					text: data.translation,
					original_text: message.original_text || message.text,
					translated_text: data.translation,
					is_translation_shown: true
				});
				setIsTranslationFetched(true);
			},
			error: (error) => {
				setIsTranslationFetched(true);
				showNotification({
					message: error?.response?.data?.message || t('something_went_wrong'),
					variant: 'error'
				});
			}
		}
	});

	//If user translates any message, lives, chat or case, and opens it again without refreshing page, we should initially show translated message
	useEffect(() => {
		if (is_translation_shown) {
			updateMessageText({
				text: translated_text || '',
				translated_text: translated_text,
				original_text: original_text,
				is_translation_shown: true
			});
		}
	}, [is_translation_shown]);

	//If user changes translation language, then we need to re-fetch message with new language or reset message that was translated to the previous language.
	useEffect(() => {
		const userSelectedLanguage = userData?.content_translation_language?.id;

		if (is_translation_shown && cachedTranslationLanguageCode !== userSelectedLanguage) {
			setIsTranslationFetched(false);
			fetchData({
				values: {
					message: id || message_id,
					language: userSelectedLanguage
				}
			});
		} else if (!is_translation_shown) {
			updateMessageText({
				text: original_text || text,
				translated_text: '',
				original_text: original_text,
				is_translation_shown: false
			});
		}
	}, [userData?.content_translation_language?.id]);

	function updateMessageText({
		text,
		is_translation_shown,
		original_text = '',
		translated_text = ''
	}: UpdatedField) {
		updateEntities({
			entity: entityType === 'case' ? 'caseMessages' : 'imsMessages',
			entityId: custom_uuid,
			updatingData: {
				text,
				original_text,
				translated_text,
				is_translation_shown
			}
		});
	}

	const handleTranslate = useCallback(() => {
		//translating
		if (!is_translation_shown) {
			if (!translated_text) {
				setIsTranslationFetched(false);
				fetchData({
					values: {
						message: id || message_id,
						language: userData?.content_translation_language?.id
					}
				});
			} else {
				//getting translation from 'cache' if it has been translated before | initially message has not 'translated_text' field
				updateMessageText({
					text: translated_text || '',
					translated_text: translated_text,
					original_text: original_text,
					is_translation_shown: true
				});
			}

			//reverting to original text
		} else {
			updateMessageText({
				text: original_text || '',
				translated_text: translated_text,
				original_text: original_text,
				is_translation_shown: false
			});
		}
	}, [is_translation_shown, message, message_id, id, userData?.content_translation_language?.id]);

	return {
		handleTranslate,
		isTranslationFetched
	};
}

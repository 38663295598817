import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { KntButton } from 'shared/components/Elements';
import { KntInput } from 'shared/components/Fields';

type Props = {
	error: string | null;
	onPasswordInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
	onCancelBtnClicked: () => void;
	onConfirmBtnClicked: () => void;
};

export function PDFPasswordInputConfirm(props: Props) {
	const { t } = useTranslation();
	return (
		<div className="password-container">
			<h3 className="password-container__title mb-2.5 text-kgrey-2d2d2d dark:text-white">
				{t('pdf_document_protected')}
			</h3>
			<KntInput
				classNames="password-container__input"
				name="pdf_password"
				placeholder={t('enter_document_password')}
				onChange={props.onPasswordInputChange}
				error={!!props.error}
				helperText={t(props.error as string)}
			/>
			<div className="password-container__buttons">
				<KntButton
					className="password-container__buttons--cancel"
					onClick={props.onCancelBtnClicked}
				>
					{t('cancel')}
				</KntButton>
				<KntButton
					onClick={props.onConfirmBtnClicked}
					color="primary"
					className="password-container__buttons--confirm mx-1.5"
				>
					{t('confirm_password')}
				</KntButton>
			</div>
		</div>
	);
}

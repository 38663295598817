import { useFormGuard, useQueryParams, useRouter, useWhiteModal } from 'shared/hooks';
import { CreateCaseForm } from 'pages/Cases/components/CreateCaseForm';
import { BiPlusCircle } from 'react-icons/bi';
import { useAppState } from '../../../state';
import React, { useCallback } from 'react';
import { ConfirmModal } from '../../ConfirmModal';
import { useTranslation } from 'react-i18next';

export function CreateCaseBtn() {
	const { WhiteModal } = useWhiteModal();
	const { t } = useTranslation();

	const { openCaseCreatePopup, showCaseCreatePopup } = useAppState();
	const { removeQueryParams } = useQueryParams();
	const { location, history } = useRouter();

	const { formValues, guardOpen, setGuardOpen, formValuesSetter } = useFormGuard();
	const isInCasePage = location.pathname.includes('/case-messages');

	const onPopupClose = () => {
		if (formValues.length) {
			setGuardOpen(true);
			return;
		}
		showCaseCreatePopup();
		//Remove client case to Staff case creation related query params. e.g. copyToStaff=true
		removeQueryParams();
	};

	const hasToShowGuard = useCallback(() => {
		if (formValues.length) {
			setGuardOpen(true);
			return;
		}
		showCaseCreatePopup(false);
	}, [formValues]);

	const confirmModalHandler = useCallback(() => {
		setGuardOpen(false);
		formValuesSetter([]);
		showCaseCreatePopup();
	}, [openCaseCreatePopup]);

	const confirmToggleHandler = useCallback(() => {
		setGuardOpen((prev: boolean) => !prev);
	}, []);

	const onCaseCreateBtnClick = useCallback(() => {
		if (!isInCasePage) {
			history.push('/case-messages');
		}
		showCaseCreatePopup(true);
	}, [isInCasePage]);

	return (
		<>
			<button
				className="group flex h-full cursor-pointer items-center border-r border-kgrey-border-solid pl-2 pr-2 dark:border-white/20 lg:border-none lg:pl-5 lg:pr-5"
				onClick={onCaseCreateBtnClick}
			>
				<span className="text-primary">
					<BiPlusCircle size={'17px'} />
				</span>
				<div className="hidden px-2 text-base leading-none text-kdark-text transition-colors group-hover:text-primary dark:text-white lg:block">
					{t('create_case')}
				</div>
			</button>
			<WhiteModal
				handleModalOpen={onPopupClose}
				open={openCaseCreatePopup}
				maxWidth={446}
				scrollable
			>
				<CreateCaseForm
					modalHandler={showCaseCreatePopup}
					hasToShowGuard={hasToShowGuard}
					formValuesSetter={formValuesSetter}
				/>
			</WhiteModal>

			{/*Create Case Form guard*/}
			<ConfirmModal
				featureHandler={confirmModalHandler}
				isOpen={guardOpen}
				toggleHandler={confirmToggleHandler}
				title={t('cancel_unsaved_changes_confirmation')}
			/>
		</>
	);
}

export function RemoveContactFieldIcon({ onClick }: { onClick?: (e: any) => void }) {
	return (
		<svg
			onClick={onClick}
			width="24"
			height="24"
			className="cursor-pointer"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="24" height="24" rx="12" fill="#FF0000" />
			<g clipPath="url(#clip0_8042_5568)">
				<path
					d="M8.63965 12H15.3596"
					stroke="white"
					strokeWidth="0.96"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_8042_5568">
					<rect width="11.52" height="11.52" fill="white" transform="translate(6.24023 6.24023)" />
				</clipPath>
			</defs>
		</svg>
	);
}

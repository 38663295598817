import React, { useEffect } from 'react';
import { WhiteModal } from './Elements';
import { BsApple } from 'react-icons/bs';
import { IconArrowDown } from '../icons/IconArrowDown';
import config from '../../config';
import { FaGooglePlay } from 'react-icons/fa';
import { useAxios } from '../hooks';
import { useAppState } from '../state';
import { SettingsDataType } from '../state/reducer';
import { storage } from '../services';
import { Trans, useTranslation } from 'react-i18next';
const imgPath = `${config.PUBLIC_ASSETS}img/`;

type Props = {
	toggleHandler: () => void;
	isOpen: boolean;
};

export function MobilePromptModal({ toggleHandler, isOpen }: Props) {
	const { companyId, settingsData, setSettingsData } = useAppState();
	const { t } = useTranslation();
	const { fetchData: saveMobilePromptShown } = useAxios({
		url: `/${companyId}/settings/`,
		method: 'post',
		dynamicValues: true,
		cb: {
			success: (data, args) => {},
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			error: (error) => {
				throw new Error(error);
			}
		}
	});
	const handleMobilePromptShown = async () => {
		const payload = {
			settings_json: {
				...settingsData?.settings_json,
				isMobilePromptShown: true
			}
		};
		const computedSettingsData = { ...settingsData, ...payload };

		try {
			await saveMobilePromptShown({
				values: payload
			});
			setSettingsData(computedSettingsData as SettingsDataType);
			storage.set('settingsData', JSON.stringify(computedSettingsData));
		} catch (e) {
			console.error('Something went wrong while saving settings', e);
		}
	};
	useEffect(() => {
		(async () => {
			if (settingsData && !settingsData?.settings_json.isMobilePromptShown) {
				await handleMobilePromptShown();
			}
		})();
	}, []);
	return (
		<>
			{isOpen ? (
				<WhiteModal
					className={'mobile-prompt-modal'}
					maxWidth={870}
					noPadding
					handleModalOpen={toggleHandler}
					open={isOpen}
				>
					<div className="flex dark:bg-kdark-dark333">
						<div className="w-full px-10 pt-14 pb-11 text-center lg:w-7/12 lg:text-left">
							<h3 className="mb-5 text-2xl font-bold leading-10 dark:text-white sm:text-3xl">
								{t('work_anywhere')}
							</h3>
							<p className="mb-8 text-lg leading-8 text-kgrey sm:mb-20">
								{t('work_anywhere_description')}
							</p>
							<div className="relative  mb-4 flex text-lg text-kgrey">
								<div className="hidden lg:block">
									<Trans
										i18nKey="scan_qr_code"
										components={{
											PrimaryText: <span className="block font-medium text-primary-dark" />
										}}
									/>
								</div>
								<div className="mt-3.5 ml-1 hidden lg:block">
									<IconArrowDown />
								</div>
							</div>

							<div className="flex w-full items-center justify-center lg:justify-start">
								<div className="mr-0 lg:mr-12">
									<a
										href={'https://apps.apple.com/us/app/kennekt-business-messenger/id1570637878'}
										target={'blank'}
										className="flex h-14 w-40 items-center rounded-md bg-primary-dark p-3 text-white no-underline duration-100 ease-linear hover:scale-105"
									>
										<BsApple size={'26'} color={'white'} />
										<div className="ml-3">
											<div className="text-10px font-medium leading-3">Download on the</div>
											<div className="text-base font-bold leading-5">App Store</div>
										</div>
									</a>
									<a
										href={
											'https://play.google.com/store/apps/details?id=com.kennekt.businessmobile\n'
										}
										target={'blank'}
										className="border-box mt-4 flex h-14 w-40 items-center rounded-md border-1px border-kgrey p-3 no-underline duration-100 ease-linear hover:scale-105"
									>
										<FaGooglePlay size={'26'} color={'#00A09D'} />
										<div className="ml-3">
											<div className="text-10px font-medium leading-3 text-kgrey">Get it on</div>
											<div className="text-base font-bold leading-5 text-kdark dark:text-white">
												Google Play
											</div>
										</div>
									</a>
								</div>
								<div className="hidden h-32 w-32 lg:inline-block">
									<img src={`${imgPath}/qr-mobile-apps.png`} />
								</div>
							</div>
						</div>
						<div className="hidden w-5/12 overflow-hidden bg-primary-dark lg:inline-block">
							<img
								className={'translate-x-2'}
								src={`${imgPath}/mobile-prompt.png`}
								alt={'Mobile img'}
							/>
						</div>
					</div>
				</WhiteModal>
			) : null}
		</>
	);
}

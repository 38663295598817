import { IAction } from 'shared/interfaces';

// type Entity = { [key: string]: object };
type Entity = {
	[key: string]: {
		[key: string]: any;
	};
};

type UpdatedEntity = {
	entity: string;
	entityId: string;
	data: any;
};

type ResetEntity = {
	entity: string;
};

export enum EntitiesActionTypes {
	LOAD_ENTITIES_TRIGGER = 'LOAD_ENTITIES_TRIGGER',
	LOAD_ENTITIES_REQUEST = 'LOAD_ENTITIES_REQUEST',
	LOAD_ENTITIES_SUCCESS = 'LOAD_ENTITIES_SUCCESS',
	LOAD_ENTITIES_FAILURE = 'LOAD_ENTITIES_FAILURE',
	UPDATE_ENTITIES_TRIGGER = 'UPDATE_ENTITIES_TRIGGER',
	UPDATE_ENTITIES_REQUEST = 'UPDATE_ENTITIES_REQUEST',
	UPDATE_ENTITIES_SUCCESS = 'UPDATE_ENTITIES_SUCCESS',
	UPDATE_ENTITIES_FAILURE = 'UPDATE_ENTITIES_FAILURE',
	RESET_ENTITIES_LIST = 'RESET_ENTITIES_LIST'
}

export type LoadEntitiesActionSuccess = IAction<EntitiesActionTypes.LOAD_ENTITIES_SUCCESS, Entity>;

export type UpdateEntitiesActionSuccess = IAction<
	EntitiesActionTypes.UPDATE_ENTITIES_SUCCESS,
	UpdatedEntity
>;

export type ResetEntityActionSuccess = IAction<
	EntitiesActionTypes.RESET_ENTITIES_LIST,
	ResetEntity
>;

// export function loadEntitiesSuccess(entity: Entity): LoadEntitiesActionSuccess {
export function loadEntitiesSuccess(entity: any): LoadEntitiesActionSuccess {
	return {
		type: EntitiesActionTypes.LOAD_ENTITIES_SUCCESS,
		payload: entity
	};
}

function updateEntitiesSuccess(entityData: UpdatedEntity): UpdateEntitiesActionSuccess {
	return {
		type: EntitiesActionTypes.UPDATE_ENTITIES_SUCCESS,
		payload: entityData
	};
}

function resetEntitiesList(payload: ResetEntity): ResetEntityActionSuccess {
	return {
		type: EntitiesActionTypes.RESET_ENTITIES_LIST,
		payload
	};
}

const entitiesActions = {
	loadEntitiesSuccess,
	updateEntitiesSuccess,
	resetEntitiesList
};

export default entitiesActions;

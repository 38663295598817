export function IconContact() {
	return (
		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.6905 13.1248C10.1567 13.1248 9.40712 12.9317 8.28421 12.304C6.91921 11.5387 5.86337 10.8317 4.50537 9.47717C3.19579 8.16875 2.55879 7.32175 1.66746 5.6995C0.660042 3.86725 0.831542 2.90708 1.02346 2.49642C1.25213 2.00525 1.58929 1.71183 2.02563 1.42075C2.27345 1.25853 2.5357 1.1195 2.80904 1.00542L2.88488 0.972166C3.01963 0.910916 3.22496 0.818749 3.48454 0.917333C3.65779 0.982666 3.81238 1.11567 4.05504 1.35483C4.55204 1.84483 5.23046 2.93567 5.48129 3.47233C5.64929 3.83342 5.76071 4.072 5.76129 4.33917C5.76129 4.65242 5.60379 4.89333 5.41246 5.15408L5.30629 5.29525C5.09863 5.56883 5.05254 5.64758 5.08287 5.78875C5.14354 6.07225 5.59854 6.91633 6.34579 7.66242C7.09304 8.40792 7.91263 8.83375 8.19729 8.89442C8.34487 8.92592 8.42479 8.87867 8.70771 8.66283C8.74796 8.63192 8.78937 8.60042 8.83312 8.56833C9.12479 8.35133 9.35463 8.19792 9.66029 8.19792H9.66204C9.92804 8.19792 10.1561 8.31342 10.533 8.50358C11.0253 8.75208 12.1494 9.42233 12.6423 9.91933C12.8826 10.1614 13.0156 10.3154 13.0815 10.4887C13.1801 10.7494 13.0874 10.9536 13.0267 11.0901L12.9935 11.1653C12.8785 11.4382 12.7387 11.6998 12.5758 11.947C12.2847 12.3816 11.9901 12.7182 11.499 12.9468C11.2464 13.0665 10.9699 13.1277 10.6905 13.1248Z"
				fill="#A5A7B2"
			/>
		</svg>
	);
}

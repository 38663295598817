import Scrollbars, { ScrollbarProps } from 'react-custom-scrollbars-2';
import { useAppState } from 'shared/state';

export function KntScrollBars(props: ScrollbarProps) {
	const { isDarkMode } = useAppState();
	return (
		<Scrollbars
			{...props}
			renderThumbVertical={(props) => (
				<div
					{...props}
					style={{
						...props.style,
						backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.2)'
					}}
				/>
			)}
		>
			{props.children}
		</Scrollbars>
	);
}

import AsyncSelect from './AsyncSelect';
import AsyncSelectSubDep from './AsyncSelectSubDep';
import AsyncSelectJs from './AsyncSelectJs';
import QuillEditorNews from './QuillEditorNews';
// import RichText from './RichText';
export * from './KntInput';
export * from './KntInputField';
export * from './CustomDTPicker';
export * from './KntPhoneInput';
export { KntPhoneInputField } from './KntPhoneInputField';
export { SelectField } from './SelectField';
export { KntAutoSizeTextField } from './KntAutoSizeTextField';
export { CustomDatePicker } from './CustomDatePicker';
export { InputWithSelect } from './InputWithSelect';

const fields = {
	AsyncSelect,
	AsyncSelectSubDep,
	AsyncSelectJs,
	QuillEditorNews
	// RichText
};

export default fields;
export { AsyncSelect, AsyncSelectSubDep };

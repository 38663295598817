import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import { isMobile } from 'react-device-detect';
import { useAppState } from '../../state';
import clsx from 'clsx';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

type Props = {
	handleModalOpen: () => void;
	children: React.ReactNode;
	open: boolean;
	title?: string;
	// eslint-disable-next-line react/no-unused-prop-types
	minWidth?: number;
	// eslint-disable-next-line react/no-unused-prop-types
	maxWidth?: number;
	// eslint-disable-next-line react/no-unused-prop-types
	noPadding?: boolean;
	// eslint-disable-next-line react/no-unused-prop-types
	cropRounded?: boolean;
	scrollable?: boolean;
	className?: string;
	containerClasses?: string;
	paperWidth?: number;
	noMaxHeight?: boolean;
};

const equalWithoutChildren = (prev: any, next: any) => {
	// eslint-disable-next-line no-restricted-syntax
	for (const k in prev) {
		// eslint-disable-next-line no-continue
		if (k === 'children') continue;
		if (prev[k] !== next[k]) return false;
	}
	return true;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			overflow: (props: Props) => (props.cropRounded ? 'hidden' : 'visible'),
			borderRadius: '16px',
			[theme.breakpoints.up('md')]: {
				minWidth: (props: Props) => (props.minWidth ? `${props.minWidth}px` : '446px'),
				maxWidth: (props: Props) => (props.maxWidth ? `${props.maxWidth}px` : 'auto')
			},
			[theme.breakpoints.down('sm')]: {
				width: (props: Props) => (props.paperWidth ? `${props.paperWidth}px` : '100%'),
				maxWidth: (props: Props) => (props.paperWidth ? `${props.paperWidth}px` : '600px'),
				overflow: (props: Props) => (props.scrollable ? 'scroll' : 'visible'),
				margin: '10px'
			}
		},
		contentRoot: {
			padding: (props: Props) =>
				props.noPadding ? 0 : isMobile ? theme.spacing(2) : theme.spacing(3),
			overflow: 'visible'
		},
		container: {
			position: (props: Props) => (props.scrollable ? 'static' : 'fixed'),
			height: (props: Props) => (props.scrollable ? 'auto' : '100%'),
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			overflow: 'auto'
		},
		scrollPaper: {
			maxHeight: '3000px'
		},
		dialogRootTitle: {
			padding: isMobile ? '12px 16px' : '16px 24px'
		},
		root: {
			/* fix: 4330 - Modal backdrop causing to block UI interactivity when CPU intensive state updates is happening sometimes, 
			so I am disabling pointer events when Modal backdrop opacity is 0 which modal is closed already */
			['&:has(> div[style*="opacity: 0"])']: {
				pointerEvents: 'none'
			},
			overflowY: (props: Props) => (props.scrollable ? 'auto' : 'hidden')
		}
	})
);

// eslint-disable-next-line react/function-component-definition
const WhiteModalStandard: React.FC<Props> = (props) => {
	const {
		handleModalOpen,
		open,
		title,
		children,
		noMaxHeight,
		className = '',
		containerClasses = ''
	} = props;
	const { isRTL } = useTranslationHelpers();
	const { isDarkMode } = useAppState();
	const classes = useStyles(props);
	return (
		<Dialog
			BackdropProps={{
				style: {
					backgroundColor: isDarkMode ? 'rgba(0,0,0, .5)' : 'rgba(255,255,255, .5)',
					backdropFilter: 'blur(3px)'
				}
			}}
			onClose={handleModalOpen}
			aria-labelledby="simple-dialog-title"
			open={open}
			className={clsx(className, { 'rtl-dialog': isRTL })}
			classes={{
				root: classes.root,
				paper: classes.paper,
				container: `${classes.container} ${containerClasses}`,
				...{ ...(noMaxHeight ? {} : { paperScrollPaper: classes.scrollPaper }) }
			}}
		>
			{title && <DialogTitle classes={{ root: classes.dialogRootTitle }}>{title}</DialogTitle>}
			<DialogContent classes={{ root: classes.contentRoot }}>{children}</DialogContent>
		</Dialog>
	);
};

WhiteModalStandard.defaultProps = {
	title: '',
	minWidth: 446,
	maxWidth: 1920,
	noPadding: false,
	cropRounded: false,
	scrollable: false
};

export const WhiteModal = React.memo(WhiteModalStandard, equalWithoutChildren);

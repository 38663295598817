import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
// import AsyncPaginate from "react-select-async-paginate";
import { AsyncPaginate } from 'react-select-async-paginate';
import { api, queryBuilder } from 'shared/services';
import { useAppState } from '../../state';
import { useTranslation } from 'react-i18next';

const propTypes = {
	title: PropTypes.string.isRequired,
	className: PropTypes.string,
	optionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	optionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	isSearchable: PropTypes.bool,
	menuPlacement: PropTypes.string,
	customStyles: PropTypes.string
};
const defaultProps = {
	title: '',
	className: null,
	optionValue: 'id',
	optionLabel: 'title',
	isSearchable: false,
	menuPlacement: 'bottom',
	disableOptions: [],
	loadOptionsKey: 'items'
};

const load = async (
	search,
	prevOptions,
	page,
	url,
	loadOptionsKey,
	filterParams,
	params,
	reverse
) => {
	const pageCount = 10;

	const { data } = await api.request.get(
		queryBuilder(url, {
			page,
			limit: pageCount,
			filter: filterParams(search),
			...(typeof params === 'function' ? params(search) : params)
		})
	);

	const optionCount = data.count;
	const nextUrl = data && get(data, 'next');
	const indexUrl = nextUrl && nextUrl.indexOf('?');
	const queryParams = nextUrl && nextUrl.slice(indexUrl);
	const splitted = queryParams && queryParams.split('&')[0].split('=')[1];
	const currentPage = splitted && Number(splitted) - 1;

	let last_page;
	if (optionCount % pageCount === 0) {
		last_page = optionCount / pageCount;
	} else {
		last_page = Math.floor(optionCount / pageCount) + 1;
	}

	let options = [];

	if (reverse) {
		options =
			typeof loadOptionsKey === 'function'
				? loadOptionsKey(data).reverse()
				: loadOptionsKey
				? get(data, loadOptionsKey).reverse()
				: data.reverse();
	} else {
		options =
			typeof loadOptionsKey === 'function'
				? loadOptionsKey(data)
				: loadOptionsKey
				? get(data, loadOptionsKey)
				: data;
	}

	return {
		options: options,
		hasMore: currentPage < last_page && currentPage !== null,
		additional: { page: currentPage + 1 }
	};
};

function AsyncSelect({
	disableOptions,
	className,
	label,
	isMulti,
	placeholder,
	options,
	optionLabel,
	optionValue,
	isSearchable,
	isClearable,
	menuPlacement,
	loadOptionsUrl,
	loadOptionsKey,
	loadOptionsParams = {},
	filterParams = () => ({}),
	onCreateOption,
	SelectComponent,
	isLoading,
	reverse = false,
	onClearChange,
	onChange,
	customStyles,
	alwaysRefetch = false,
	customStylesProps,
	...rest
}) {
	const [loading, setLoading] = useState(false);
	const [canUpdate, setCanUpdate] = useState(false);
	const { isDarkMode } = useAppState();
	const {t} = useTranslation()

	useEffect(() => {
		if (canUpdate) {
			setLoading(true);
		}
	}, [canUpdate]);

	useEffect(() => {
		if (loading) setLoading(false);
	}, [loading]);

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		if (canUpdate) {
	// 			setLoading(true);
	// 		}
	// 	}, [10000]);
	// 	setTimeout(() => {
	// 		if (canUpdate) {
	// 			setLoading(false);
	// 		}
	// 	}, [10001]);
	// }, [canUpdate]);

	// useEffect(() => {
	// 	setLoading(true);
	// 	return setLoading(false);
	// }, [loadOptionsUrl, loadOptionsParams]);

	const KntTwilioStyle = {
		control: (provided, state) => {
			// console.log('select state', state);

			return {
				...provided,
				cursor: 'pointer',
				boxShadow: 'none',
				border: '1px solid #B8BCCF',
				borderRadius: '12px',
				opacity: state.isDisabled && isDarkMode && 0.5,
				'&:hover': {
					// borderBottom: "1px solid #40a9ff"
				}
			};
		},
		valueContainer: (provided) => ({
			...provided,
			padding: '3px 8px'
		}),
		dropdownIndicator: () => ({
			paddingRight: '5px',
			// paddingTop: '5px',
			fill: '#1C162E'
		}),
		singleValue: (provided) => ({
			color: '#424653',
			fontWeight: '300',
			fontSize: '15px'
		}),
		indicatorSeparator: (provided) => ({
			...provided,
			display: 'none'
		}),
		placeholder: (provided) => ({
			...provided,
			color: '#424653',
			fontWeight: '300',
			fontSize: '15px'
		})
	};

	const selectStyle = (style) => {
		switch (style) {
			case 'KntTwilioStyle': {
				return KntTwilioStyle;
			}
			default:
				return {};
		}
	};

	return (
		!loading ? (
			<AsyncPaginate
				noOptionsMessage={() => t('no_options')}
				loadingMessage={() => `${t('loading')}...`}
				className={"react-select-paginate"}
				styles={customStylesProps ? customStylesProps : customStyles && selectStyle(customStyles)}
				debounceTimeout={300}
				onChange={(option) => {
					if (typeof onChange === 'function') {
						onChange(option);
					}
					if (typeof onClearChange === 'function' && option === null) {
						onClearChange(null);
					}
				}}
				onMenuClose={() => {
					alwaysRefetch && setCanUpdate(true);
				}}
				onMenuOpen={() => {
					alwaysRefetch && setCanUpdate(false);
				}}
				getValue={(option) => option[optionValue]}
				getOptionLabel={(option) =>
					typeof optionLabel === 'function' ? optionLabel(option) : option[optionLabel]
				}
				getOptionValue={(option) =>
					typeof optionValue === 'function' ? optionValue(option) : option[optionValue]
				}
				additional={{ page: 1 }}
				loadOptions={(search, prevOptions, { page }) =>
					load(
						search,
						prevOptions,
						page,
						loadOptionsUrl,
						loadOptionsKey,
						filterParams,
						loadOptionsParams,
						reverse
					)
				}
				isOptionDisabled={(option) =>
					disableOptions.reduce((prev, curr) => [...prev, curr.id], []).includes(option.id)
				}
				{...{
					isMulti,
					options,
					placeholder,
					isSearchable,
					isClearable,
					menuPlacement,
					onCreateOption,
					SelectComponent,
					isLoading
				}}
				{...rest}
			/>
		) : null
	);
}

AsyncSelect.propTypes = propTypes;
AsyncSelect.defaultProps = defaultProps;

export default AsyncSelect;

import { useChatContext, useChatInputContext } from 'shared/contexts';
import { storage } from 'shared/services';
import { useUpdateEntities } from '../../useUpdateEntities';
import { TSaveEntityDraftPayload } from './draftHooks.types';
import { EntityDraft } from 'pages/GroupChat/types';

const SHOW_LOGS = false;

export function useDraftFunctions() {
	const { entityType, entityId } = useChatContext();
	const { message, reply, editingMessage } = useChatInputContext();
	const { updateEntities } = useUpdateEntities();

	function getLS(entityIdArg?: number): { [key: string]: any } {
		const entity = JSON.parse(storage.get(`${entityType}Draft`) || '{}');
		if (entityIdArg) {
			if (entityIdArg in entity) return entity[entityIdArg];
		}
		return entity;
	}

	//Here requiring payload parameter, when type === "add" with the help of function overloading.
	//More info about function overloading: https://www.typescriptlang.org/docs/handbook/2/functions.html#overload-signatures-and-the-implementation-signature
	function updateEntityDraft(type: 'add', payload: TSaveEntityDraftPayload): void;
	function updateEntityDraft(type: 'reset'): void;

	function updateEntityDraft(type: 'add' | 'reset', payload?: TSaveEntityDraftPayload) {
		let draftMessage = null;

		if (type === 'add' && payload) {
			const { editingMessage, entityId, message, reply } = payload;
			draftMessage = {
				editing_id: editingMessage ? editingMessage.id : null,
				id: entityId,
				reply_to: reply && reply.id ? reply.id : null,
				text: message
			};
		}

		updateEntities({
			entity: entityType === 'case' ? 'cases' : 'ims-chats',
			entityId,
			updatingData: {
				draft_message: draftMessage
			}
		});
	}

	function removeLS(entityIdArg: number = entityId, callback?: () => void) {
		const entity = { ...getLS() };
		if (entityIdArg in entity) delete entity[entityIdArg];
		if (process.env.NODE_ENV !== 'production' && SHOW_LOGS) {
			console.log('removing draft', entity);
		}

		storage.set(`${entityType}Draft`, JSON.stringify(entity));
		if (typeof callback === 'function') setTimeout(callback, 0);
	}

	function updateLS(entityId: number, entityType: string, updatingData: Partial<EntityDraft>) {
		const entity = { ...getLS() };

		if (entityId in entity) {
			entity[entityId] = {
				...entity[entityId],
				...updatingData
			};
		}

		storage.set(`${entityType}Draft`, JSON.stringify(entity));
	}

	return {
		getLS,
		updateEntityDraft,
		removeLS,
		updateLS
	};
}

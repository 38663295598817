import { useAppState } from 'shared/state';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import {
	decrementEntityTotal,
	formSuccess,
	incrementEntityTotal,
	loadAllRequest,
	loadAllSuccess,
	loadOneRequest
} from 'modules/entity/actions';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import React from 'react';
import { Button } from 'shared/components/Elements';
import Actions from 'store/actions';
import { useUpdateEntity } from 'shared/hooks/useUpdateEntity';
import { Case, IFileMsgType, IGroupChat, IRootState, IUserMessage } from 'shared/interfaces';
import {
	useAxios,
	useCasesRefetch,
	useFilterTemplateState,
	useLoadAll,
	useLoadOne,
	useNotistack,
	useQueryParams,
	useRouter,
	useUpdateEntities
} from 'shared/hooks';
import { CaseType } from 'pages/Cases/components/CaseItem';
import { ConfirmStatusType, DraftMessagePayload, StaffEditedType } from '../index';
import { storage } from 'shared/services';
import { useCaseExtraParams } from 'pages/Cases/hooks/useCaseExtraParams';
import config from 'config';
import { BusinessCompany, Company } from 'modules/user/reducers';
import userActions from 'modules/user/actions';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { PriorityStrings, PriorityType } from 'shared/typings/priority.types';
import { useEntityForm } from 'modules/entity/hooks/useEntityForm';
import { buildGroupParams, CHATS_MESSAGES_LIMIT } from 'shared/consts/groupChat.const';
import { nanoid } from 'nanoid';
import { casesEventChannel } from 'eventChannels/cases';
import { useTranslation } from 'react-i18next';

const UpdateEntities = Actions.entities.updateEntitiesSuccess;
const SetSelectedUserCompany = Actions.user.setUserCompany;

export function useSocketHelpers() {
	const {
		unreadMessagesCount,
		groupsUnreadCountData,
		setGroupUnreadMessage,
		setUnreadMessagesCount,
		groupSearchQuery,
		companyId,
		searchQuery,
		caseType,
		selectedCaseFilter,
		role,
		unreadCasesCountByStatus,
		setUnreadCasesCountByStatus,
		scheduledMessageIds,
		setChatUuid
	} = useAppState();
	const { t } = useTranslation();
	const { removeQueryParams } = useQueryParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const entityObjectInReduxState = useSelector((state: IRootState) => state.entity);
	const entitiesObjectInReduxState = useSelector((state: IRootState) => state.entities);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { appendToStore } = useUpdateEntity();
	const { updateEntities } = useUpdateEntities();
	const { reloadEntityOne } = useLoadOne();
	const { reloadEntityAll } = useLoadAll();
	const { showNotification: showNotistack } = useNotistack();
	const { location } = useRouter();
	const { extraParams, casesUrl } = useCaseExtraParams({ statusId: null });
	const { userData, companies, company: companyInRedux } = useAuth();
	const parsedSelectedCaseFromLS = JSON.parse(storage.get('selectedCaseFilter') || '{}')[caseType];
	const selectedFilterTemplate = selectedCaseFilter[caseType] || parsedSelectedCaseFromLS;

	const { priorityFilter, hasPinnedFilter, filterType } = useFilterTemplateState();

	const { handleLocalDelete } = useEntityForm({
		deleteData: true,
		method: 'delete',
		url: '',
		entity: 'cases',
		name: '',
		cb: {
			success: () => {},
			error: () => {},
			finally: () => {}
		}
	});

	// For prepending new created case in other device in realtime or fetch not loaded case
	const { fetchData: fetchSingleCase } = useAxios({
		url: '',
		dynamicUrl: true,
		cb: {
			success: (data, args) => {
				if (args[0]?.notLoadedCase) {
					handleNewCaseCreation(data, false);
					return;
				}

				const isCurrentUser = userData.id === data.created_by.id;

				//prepend case and show notification only if case is not from current user
				if (!isCurrentUser) {
					handleNewCaseCreation(data);
				}
			},
			error: (error, args) => {
				console.error('error fetching case');
			}
		}
	});

	//loading companies list before switching to another business
	const { fetchData: fetchBusiness } = useAxios({
		url: '',
		dynamicUrl: true,
		cb: {
			success: async ({ results }, args) => {
				const newCompany = results.find((cmp: Company) => cmp.company.id === companyId);

				storage.set('company', JSON.stringify(newCompany));
				dispatch(userActions.setUserCompany(newCompany));

				//show notification about role has been changed
				showNotistack({ message: t('your_role_has_been_changed'), variant: 'info' });

				//reload staff list if user in staff management page
				if (location.pathname.includes('staffs-management')) {
					reloadEntityAll({
						entity: 'staffEmployee',
						name: `staffEmployee-${companyId}`,
						url: `/${companyId}/employee/`
					});
				}
			},
			error: (error, args) => {}
		}
	});

	function incrementMsgUnread(entityType: 'case' | 'ims', entityId: number) {
		const unreadMessagesCountObject =
			entityType === 'case' ? unreadMessagesCount : groupsUnreadCountData;
		const unreadUpdateSetter =
			entityType === 'case' ? setUnreadMessagesCount : setGroupUnreadMessage;

		let unreadCount = { ...unreadMessagesCountObject };

		if (entityId in unreadCount) {
			unreadCount = { ...unreadCount, [entityId]: unreadCount[entityId] + 1 };
		} else {
			unreadCount = { ...unreadCount, [entityId]: 1 };
		}
		unreadUpdateSetter(unreadCount);
	}

	function updateListUnread(
		entityType: string,
		entityId: number,
		status: number | null | undefined,
		text: string,
		fileType: string | undefined | null,
		fromUser: IUserMessage,
		is_status_change: boolean,
		isNotActiveEntity: boolean,
		shouldReFetchCase = true
	) {
		const { ids, params, entityName, entity, meta } = getEntityData(entityType, status);

		const isStatusNotFiltered = entityObjectInReduxState.cases?.[entityName];

		// Update unread count only for others message(not for own message)
		if (userData.id !== fromUser.id) {
			const unreadMessagesCountObject =
				entityType === 'case' ? unreadMessagesCount : groupsUnreadCountData;
			const unreadUpdateSetter =
				entityType === 'case' ? setUnreadMessagesCount : setGroupUnreadMessage;

			let unreadCount = { ...unreadMessagesCountObject };
			if (entityId in unreadCount) {
				unreadCount = { ...unreadCount, [entityId]: unreadCount[entityId] + 1 };
			} else {
				unreadCount = { ...unreadCount, [entityId]: 1 };
			}

			if (isNotActiveEntity) {
				unreadUpdateSetter(unreadCount);
			}
		}
		const casesObjects = entitiesObjectInReduxState?.[entity];

		// Raise to top of status if new message received for that status if search mode is not active
		if (casesObjects && ids?.includes(entityId) && !searchQuery) {
			// we have not to update unread in active case or chat item
			const newEntityIds = moveEntityIdToBeginning(ids as number[], entityId);

			if (isStatusNotFiltered || entityType === 'ims') {
				// drop case to the changed status if that status is not filtered
				liftEntityToTop(newEntityIds, entity, entityName, params, meta);
			}

			// if (!is_status_change) {
			// Update last message
			dispatch(
				UpdateEntities({
					entity,
					entityId: String(entityId),
					data: {
						last_message_time: dayjs().unix(),
						last_message_text: text,
						last_message_type: fileType,
						last_message_author: {
							first_name: fromUser.first_name,
							last_name: fromUser.last_name
						}
					}
				})
			);
			// }
		}

		// if case has not been loaded we must fetch it and prepend it to case  list
		if (entityType === 'case' && casesObjects && !ids?.includes(entityId) && shouldReFetchCase) {
			fetchSingleCase({ url: `/${companyId}/cases/as_business/${entityId}/`, notLoadedCase: true });
			dispatch(incrementEntityTotal('cases', entityName));
		}
	}

	function getEntityData(entityType: string, status: null | number | undefined) {
		const entity = entityType === 'case' ? 'cases' : 'ims-chats';
		const caseEntityName = `AllBusinessCases-${status}-${caseType}-${
			searchQuery.length
				? 'search'
				: `${selectedFilterTemplate ? selectedFilterTemplate.id : 'normal'}`
		}`;
		const entityName =
			entityType === 'case' ? caseEntityName : `AllImsChats-${companyId}-${groupSearchQuery}`;

		return {
			entity,
			entityName,
			ids: entityObjectInReduxState[entity]?.[entityName]?.ids,
			meta: entityObjectInReduxState[entity]?.[entityName]?.meta,
			params: entityObjectInReduxState[entity]?.[entityName]?.params
		};
	}

	function reloadCaseOrGroupChat({
		entityType,
		entityId
	}: {
		entityType: string;
		entityId: number;
	}) {
		const isCase = entityType === 'case';

		const entityName = isCase ? `CaseChat-${entityId}` : `GroupChat-${entityId}`;
		const url = isCase
			? `/${companyId}/cases/as_business/${entityId}/`
			: `/${companyId}/ims/groups/${entityId}/`;

		reloadEntityOne({
			entity: isCase ? 'cases' : 'ims-chats',
			name: entityName,
			entityId,
			url
		});
	}

	// eslint-disable-next-line default-param-last
	function moveEntityIdToBeginning(
		ids: number[] = [],
		entityId: number,
		isMovingToBeginning = true
	) {
		return isMovingToBeginning
			? [entityId, ...ids.filter((id: number) => id !== entityId)]
			: [...ids.filter((id: number) => id !== entityId), entityId];
	}

	function liftEntityToTop(
		ids: number[],
		entity: string,
		entityName: string,
		params: any,
		meta: any
	) {
		dispatch(
			loadAllSuccess({
				ids,
				entity,
				name: entityName,
				appendData: false,
				prependData: false,
				params,
				meta,
				infiniteScroll: false,
				isUniq: false,
				replaceIds: true
			})
		);
	}

	type MessageNotif = {
		messageText: string;
		entity_type: string;
		entity_id: number;
		case_status_id: number | null | undefined;
		fileType: IFileMsgType;
		case_number?: string;
		businessCompanyId: number;
		showButtons?: boolean;
		businessName?: string;
		clientCompanyId?: number;
		isBeingDeletedMember?: boolean;
		group_name?: string;
	};

	type GetMessageNotificationContent = {
		isCaseNotif: boolean;
		businessName: string;
		caseNumber?: string;
		groupName?: string;
		content: string | null;
	};

	function getMessageNotificationContent({
		isCaseNotif,
		businessName,
		caseNumber,
		groupName,
		content
	}: GetMessageNotificationContent): string | React.ReactNode {
		if (isCaseNotif) {
			return `${businessName ? businessName + ' |' : ''} #${caseNumber}  : ${content}`;
		}

		return (
			<>
				{businessName ? (
					<b>
						{businessName ? businessName + ' |' : ''} {groupName}
					</b>
				) : null}

				<p>{content}</p>
			</>
		);
	}

	function showNewMessageNotification({
		messageText,
		entity_type,
		entity_id,
		case_status_id,
		fileType,
		case_number,
		businessCompanyId,
		showButtons = true,
		businessName = '',
		clientCompanyId,
		isBeingDeletedMember = false,
		group_name
	}: MessageNotif) {
		const isCase = entity_type === 'case';
		const isFromSelectedBusiness = businessCompanyId === companyId;

		const isStaff = clientCompanyId === businessCompanyId;

		const computedEntityType = isCase ? 'cases' : 'ims-chats';
		const computedCaseType = isStaff ? config.STAFF : config.CLIENT;
		const currentEntity = entitiesObjectInReduxState?.[computedEntityType]?.[entity_id] as
			| CaseType
			| IGroupChat;
		const computedUnreadEntityChatQueries = currentEntity?.last_read_message
			? `&hasUnreadMessage=true&lastReadMessage=${currentEntity?.last_read_message}&unread=${currentEntity?.last_read_message}`
			: '';

		let actionUrl = isCase
			? {
					pathname: '/case-messages',
					search: `?case=${entity_id}&status=${case_status_id}${computedUnreadEntityChatQueries}`
			  }
			: {
					pathname: '/group-chat',
					search: `?chat=${entity_id}${computedUnreadEntityChatQueries}`
			  };
		if (!isFromSelectedBusiness) {
			actionUrl = {
				pathname: '/preloader',
				search: `?entity_type=${entity_type}&entity_id=${entity_id}&status=${case_status_id}&business_company=${businessCompanyId}&type=CHAT&caseType=${computedCaseType}`
			};
		}

		// console.log('actionUrl', actionUrl);

		const msgInNotification = messageText.length ? messageText : getMediaMessageByType(fileType);

		const onButtonNotifButtonClick = () => {
			history.push(actionUrl);
			setTimeout(() => {
				//3671 - reconnecting chatSocket properly when case/group is opened via notification
				setChatUuid(nanoid());
				closeSnackbar();
			}, 0);
		};

		showNotification({
			message: getMessageNotificationContent({
				isCaseNotif: isCase,
				caseNumber: case_number,
				groupName: group_name,
				businessName,
				content: msgInNotification
			}),
			type: 'new_message',
			...{
				...(showButtons
					? {
							action: (key: any) => (
								<>
									{!isBeingDeletedMember && (
										<Button
											className={clsx({
												'!mt-4': entity_type === 'ims'
											})}
											classes={{ label: 'text-white' }}
											onClick={onButtonNotifButtonClick}
										>
											{entity_type === 'case' ? t('open_case') : t('open_chat')}
										</Button>
									)}
									<Button
										className={clsx({
											'!mt-4': entity_type === 'ims'
										})}
										classes={{ label: 'text-white' }}
										onClick={() => closeSnackbar(key)}
									>
										{t('dismiss')}
									</Button>
								</>
							)
					  }
					: {})
			}
		});
	}

	type StaffRequestType = {
		role: string;
		requesting_company: { name: string };
		is_staff_exists: boolean;
		text: string;
	};

	function showStaffInvitationNotification(request: StaffRequestType) {
		showNotification({
			message: request.text,
			type: 'staff_invitation',
			...{
				...(!request.is_staff_exists
					? {
							action: (key: any) => (
								<Button
									className={'hidden'}
									classes={{ label: 'text-white' }}
									onClick={() => history.push('/switch-business/business-requests')}
								>
									{t('open_requests_page')}
								</Button>
							)
					  }
					: {})
			}
		});
	}

	//
	//
	function showStatusChangeNotification(entity_number: string, status: null | string) {
		showNotification({
			message: `#${entity_number} : ${t('status_has_been_changed_to', { status })}`,
			type: 'status_changed',
			variant: 'info'
		});
	}

	function showPriorityChangeNotification(entity_number: string, priority: string) {
		showNotification({
			message: `#${entity_number} : ${t('priority_has_been_changed_to', { priority })}`,
			type: 'priority_changed',
			variant: 'success'
		});
	}

	type CaseCreationNotif = {
		entity_id: number;
		case_status_id: number | null | undefined;
		case_number?: string;
		business_company_id?: number;
	};

	function showCaseCreationNotification({
		entity_id,
		case_status_id,
		case_number,
		business_company_id
	}: CaseCreationNotif) {
		const isFromSelectedBusiness = business_company_id === companyId;

		let actionUrl = {
			pathname: '/case-messages',
			search: `?case=${entity_id}&status=${case_status_id}`
		};

		if (!isFromSelectedBusiness) {
			actionUrl = {
				pathname: '/preloader',
				search: `?entity_type=case&entity_id=${entity_id}&status=${case_status_id}&business_company=${business_company_id}&type=CHAT`
			};
		}
		const onButtonNotifButtonClick = () => {
			history.push(actionUrl);
			setTimeout(() => {
				closeSnackbar();
			}, 300);
		};

		showNotification({
			message: `#${case_number} :The new case has been created for you.`,
			type: 'new_case',
			action: () => (
				<Button classes={{ label: 'text-white' }} onClick={onButtonNotifButtonClick}>
					Open Case
				</Button>
			)
		});
	}

	type Notification = {
		type: string;
		message: string | React.ReactNode;
		action?: (key: any) => React.ReactNode;
		variant?: string;
		persist?: boolean;
	};

	function showNotification({ type, message, action, variant = 'success' }: Notification) {
		const options = {
			variant,
			autoHideDuration: 5000,
			action: (key: any) => (
				<Button classes={{ label: 'text-white' }} onClick={() => closeSnackbar(key)}>
					{t('dismiss')}
				</Button>
			)
		};

		switch (type) {
			case 'new_case':
			case 'staff_invitation':
			case 'new_message': {
				// @ts-ignore
				enqueueSnackbar(message, { ...options, action });
				break;
			}
			case 'priority_changed':
			case 'status_changed': {
				// @ts-ignore
				enqueueSnackbar(message, options);
				break;
			}
			default:
				// @ts-ignore
				enqueueSnackbar(message, options);
		}
	}

	type Status = number | null | undefined;

	function changeStatus(
		entityType: string,
		entityId: number,
		prevStatus: Status,
		nextStatus: Status,
		statusTitle: string
	) {
		const { entity, entityName } = getEntityData(entityType, prevStatus);
		const { entity: nextEntity, entityName: nextEntityName } = getEntityData(
			entityType,
			nextStatus
		);
		const cases = entityObjectInReduxState.cases?.[entityName];
		const nextStatusCases = entityObjectInReduxState.cases?.[nextEntityName];

		//Update unread case count in header
		if (prevStatus && nextStatus && entityId) {
			statusChangeCaseUnreadUpdate(prevStatus, nextStatus, entityId);
		}

		const hasNextStatusAnyPinCase = (
			Object.values(entitiesObjectInReduxState?.[nextEntity] ?? {}) as CaseType[]
		).some((caseObj) => caseObj.status?.id === nextStatus && caseObj?.pin_time);

		// reload status case list to display newly changed case, because we don't have this case in our redux state
		if (!cases) {
			reloadEntityAll({
				url: casesUrl,
				entity: 'cases',
				name: nextEntityName,
				params: {
					page: 1,
					limit: 10,
					extra: { ...extraParams, status: nextStatus }
				}
			});
		}

		if (hasNextStatusAnyPinCase) {
			casesEventChannel.emit('refetchCasesByStatus', Number(nextStatus));
		}

		// If changed status changed case is not in caselist, we must not dispatch status change actions, bcz it causes errors
		// (maybe has not been loaded yet due to different sorts in backend)
		if (!cases?.ids?.includes(entityId)) {
			return;
		}

		updateEntities({
			entity,
			entityId,
			updatingData: {
				status: {
					...(entitiesObjectInReduxState.cases[entityId] as CaseType).status,
					id: nextStatus,
					title: statusTitle
				}
			}
		});

		dispatch(
			formSuccess({
				id: entityId,
				entity,
				name: entityName,
				appendData: false,
				prependData: false,
				updateData: false,
				deleteData: true
			})
		);
		dispatch(decrementEntityTotal(entity, entityName));

		if (nextStatusCases) {
			dispatch(
				formSuccess({
					id: entityId,
					entity: nextEntity,
					name: nextEntityName,
					appendData: false,
					prependData: true,
					updateData: false,
					deleteData: false
				})
			);
		}
	}

	function statusChangeCaseUnreadUpdate(prevStatus: number, nextStatus: number, entityId: number) {
		if (unreadMessagesCount && entityId in unreadMessagesCount) {
			setUnreadCasesCountByStatus({
				...unreadCasesCountByStatus,
				[prevStatus]: unreadCasesCountByStatus[prevStatus]
					? --unreadCasesCountByStatus[prevStatus]
					: 0,
				[nextStatus]: unreadCasesCountByStatus[nextStatus]
					? ++unreadCasesCountByStatus[nextStatus]
					: 1
			});
		}
	}

	function changePriority(entityType: string, entityId: number, priority: string, status: number) {
		const { entity } = getEntityData(entityType, status);

		const casesObjects = entitiesObjectInReduxState?.[entity];

		// Make sure the case has been loaded
		if (casesObjects && entityId in casesObjects) {
			const {
				ids,
				params,
				entityName,
				entity: entityFromFunc,
				meta
			} = getEntityData(entityType, status);
			const newEntityIds = moveEntityIdToBeginning(ids as number[], entityId);

			dispatch(
				UpdateEntities({
					entity: entityFromFunc,
					entityId: String(entityId),
					data: {
						priority
					}
				})
			);
			liftEntityToTop(newEntityIds, entityFromFunc, entityName, params, meta);
		} else {
			if (priorityFilter && !priorityFilter.includes(priority)) {
				return;
			}
	
			//Or if it is not the UI, but it changed priority matches filter, we should add it to the UI
			//fetchSingleCase({ url: `/${companyId}/cases/as_business/${entityId}/`, notLoadedCase: true });
		}
	}

	function removeCaseFromUI(entityId: number, entityName: string, shouldDecrementTotal?: boolean) {
		handleLocalDelete(entityId, 'cases', entityName);

		if (shouldDecrementTotal) {
			//decrement total
			dispatch(decrementEntityTotal('cases', entityName));
		}
	}

	function permissionChangeUpdate(business_id: number, chatId: number) {
		dispatch(
			loadOneRequest({
				id: chatId,
				entity: 'ims-chats',
				name: `GroupChat-${chatId}`,
				url: `/${companyId}/ims/groups/${chatId}/`,
				params: {},
				primaryKey: 'id',
				relations: ''
			})
		);
	}

	type MemberChange = {
		businessId: number;
		type: 'ADD_MEMBER' | 'REMOVE_MEMBER' | 'LEFT_MEMBER';
		entityId: number;
		text: string;
		isCurrentUserHasBeenAddedOrRemoved?: boolean;
		showNotification?: boolean;
	};

	function updateGroupChatList({
		businessId,
		type,
		entityId,
		text,
		isCurrentUserHasBeenAddedOrRemoved = false,
		showNotification = true
	}: MemberChange) {
		if (type === 'REMOVE_MEMBER' && isCurrentUserHasBeenAddedOrRemoved) removeQueryParams(); // Exit from active group chat

		if (showNotification) {
			showNewMessageNotification({
				messageText: text,
				entity_type: 'ims',
				entity_id: entityId,
				case_status_id: null,
				fileType: null,
				businessCompanyId: businessId,
				showButtons: type === 'ADD_MEMBER'
			});
		}

		// refresh group chat list => add chat or remove chat
		if (isCurrentUserHasBeenAddedOrRemoved) {
			dispatch(
				loadAllRequest({
					entity: 'ims-chats',
					name: `AllImsChats-${companyId}-${groupSearchQuery}`,
					url:
						companyId && groupSearchQuery.length === 0
							? `/${companyId}/ims/groups/`
							: `/${companyId}/ims/message_search/`,
					params: {
						page: 1,
						limit: CHATS_MESSAGES_LIMIT,
						extra: buildGroupParams(groupSearchQuery)
					},
					primaryKey: 'id',
					relations: '',
					dataKey: 'results',
					metaKey: '',
					appendData: false,
					prependData: true,
					infiniteScroll: true,
					isUniq: true,
					reverse: false,
					cb: {
						// eslint-disable-next-line @typescript-eslint/no-empty-function
						success: (data) => {}
					},
					replaceIds: true
				})
			);
		}
	}

	function getMediaMessageByType(
		type: 'AUDIO' | 'VIDEO' | 'CONTACT' | 'LOCATION' | 'FILE' | 'IMAGE' | null
	) {
		switch (type) {
			case 'AUDIO':
				return t('audio_message');
			case 'VIDEO':
				return t('video_message');
			case 'CONTACT':
				return t('contact_message');
			case 'IMAGE':
				return t('image_message');
			case 'LOCATION':
				return t('location_message');
			case 'FILE':
				return t('file_message');
			default:
				return null;
		}
	}

	function prependCaseToCaseList(data: Case) {
		const entityName = `AllBusinessCases-${data?.status.id}-${caseType}-${
			selectedFilterTemplate ? selectedFilterTemplate.id : 'normal'
		}`;

		appendToStore({
			entity: 'cases',
			entityName,
			prependData: true,
			primaryKey: 'id',
			entityObject: data
		});
	}

	function handleNewCaseCreation(data: Case, isNewCaseCreated = true) {
		prependCaseToCaseList(data);
		if (isNewCaseCreated) {
			// Show foreground notification
			showCaseCreationNotification({
				entity_id: data.id,
				case_number: data.case_number,
				case_status_id: data?.status?.id,
				business_company_id: data.business_company?.id
			});
		}
	}

	function connectionRequestHandler({
		requestText,
		clientCompany
	}: {
		requestText: string;
		clientCompany: BusinessCompany;
	}) {
		showNotification({ message: requestText, type: 'default' });
		reloadEntityAll({
			url: `/${companyId}/client_companies/`,
			entity: 'clients',
			name: 'AllClients'
		});

		reloadEntityAll({
			url: `/${companyId}/company_connection_requests/`,
			entity: 'incoming-request',
			name: 'AllIncoming-Requests'
		});

		reloadEntityAll({
			url: `/${companyId}/my_company_connection_requests/`,
			entity: 'outgoing-request',
			name: 'AllOutgoing-Requests'
		});

		if (clientCompany) {
			reloadEntityAll({
				url: `/${companyId}/my/company/clients/${clientCompany.id}/employee/`,
				entity: 'client-users',
				name: `AllClientUser-${clientCompany.id}`
			});
		}
	}

	function draftMessageHandler(draftObject: DraftMessagePayload, type: 'reset' | 'add' = 'add') {
		const entityId = draftObject.entity_id;
		const entityType = draftObject.entity_type;
		const entity = entityType === 'case' ? 'cases' : 'ims-chats';
		const entityName = `${draftObject.entity_type}Draft`;
		const draftLSObject = JSON.parse(storage.get(entityName)!);
		let draftMessage = null;

		function updateDraft(draftMessageParam: any) {
			updateEntities({
				entity,
				entityId,
				updatingData: {
					draft_message: draftMessageParam
				}
			});
		}

		if (type === 'add') {
			draftMessage = {
				editing_id: draftObject.editing_id,
				id: entityId,
				reply_to: draftObject.reply_to_id,
				text: draftObject.text
			};

			updateDraft(draftMessage);

			return;
		}

		if (draftLSObject && draftObject.entity_id in draftLSObject) {
			if (entityId in draftLSObject) delete draftLSObject[entityId];
			storage.set(`${entityType}Draft`, JSON.stringify(draftLSObject));
			updateDraft(draftMessage);
		}
	}

	type CasePin = {
		entityId: number;
		statusId: number;
		isPinning: boolean;
	};

	function casePinHandler({ entityId, statusId, isPinning }: CasePin) {
		const { ids, params, entityName, entity, meta } = getEntityData('case', statusId);

		if (isPinning) {
			if (ids?.includes(entityId)) {
				const newEntityIds = moveEntityIdToBeginning(ids as number[], entityId);
				if (!meta || !params) return;
				liftEntityToTop(newEntityIds, entity, entityName, params, meta);
			} else {
				// fetch cases
				fetchSingleCase({
					url: `/${companyId}/cases/as_business/${entityId}/`,
					notLoadedCase: true
				});
			}
		}

		updateEntities({
			entity: 'cases',
			entityId,
			updatingData: {
				pin_time: isPinning ? Date.now() : null
			}
		});

		if (!isPinning && hasPinnedFilter && filterType === 'and') {
			//remove case from UI
			removeCaseFromUI(entityId, entityName);
		}
	}

	type UpdateStatusName = {
		newStatusTitle: string;
		statusId: number;
		confirmed: ConfirmStatusType;
		not_confirmed: ConfirmStatusType;
		is_confirmation_required: boolean;
	};

	function updateStatusName({
		newStatusTitle,
		statusId,
		confirmed,
		not_confirmed,
		is_confirmation_required
	}: UpdateStatusName) {
		//Update only if business is selected
		updateEntities({
			entity: 'statuses',
			entityId: statusId,
			updatingData: {
				title: newStatusTitle,
				confirmed,
				not_confirmed,
				is_confirmation_required
			}
		});
	}

	type HandleAssigneeUpdateArgTypes = {
		entityId: number;

		assigneeIds: number[];
	};

	function handleAssigneeUpdated({ entityId, assigneeIds }: HandleAssigneeUpdateArgTypes) {
		updateEntities({
			entity: 'cases',
			entityId,
			updatingData: {
				assignee_ids: assigneeIds
			}
		});
		// also fetches case if it is not in REDUX Store via updateListUnread fn.
	}

	type ImsPinType = {
		chat_id: number;
		action: 'ims_pinned' | 'ims_unpinned';
		user_id: number;
	};

	function imsPinUnpinHandler(message: ImsPinType) {
		const { chat_id, action } = message;
		const { ids, params, entityName, entity, meta } = getEntityData('ims', null);
		let newEntityIds: number[];

		if (action === 'ims_pinned') {
			newEntityIds = moveEntityIdToBeginning(ids as number[], chat_id);
		} else {
			newEntityIds = moveEntityIdToBeginning(ids as number[], chat_id, false);
		}

		liftEntityToTop(newEntityIds, entity, entityName, params, meta);
	}

	type StatusReloadParamType = {
		id: number;
	};

	function checkCaseListUpdate(
		toUser: StatusReloadParamType,
		businessCompany: StatusReloadParamType
	) {
		const isCasesPage = location.pathname.includes('case-messages');

		return (
			isCasesPage &&
			userData.id === toUser?.id &&
			!searchQuery.length &&
			companyId === businessCompany?.id
		);
	}

	function fetchCasesByStatus(
		case_status_id: number,
		to_user: StatusReloadParamType,
		business_company: StatusReloadParamType
	) {
		const { entityName } = getEntityData('case', case_status_id);

		const hasToUpdateCaseList = checkCaseListUpdate(to_user, business_company);

		if (hasToUpdateCaseList) {
			reloadEntityAll({
				url: casesUrl,
				entity: 'cases',
				name: entityName,
				replaceIds: true,
				params: {
					page: 1,
					limit: 10,
					extra: { ...extraParams, status: case_status_id }
				}
			});
		}
	}

	async function handleStaffEditMessage({ user_id, role: newRole, company_id }: StaffEditedType) {
		if (Number(company_id) === companyId && user_id === userData.id && role !== newRole) {
			//Send async request to get list of user companies
			await fetchBusiness({
				url: `/user/companies/`,
				params: {
					limit: 100
				}
			});
		}
	}

	function staffListReload(from_user: any, business_company: any) {
		const isStaffPage = location.pathname.includes('staffs-management');
		if (business_company?.id === companyId && isStaffPage) {
			reloadEntityAll({
				url: `/${companyId}/employee/`,
				entity: 'staffEmployee',
				name: `staffEmployee-${companyId}`,
				replaceIds: true,
				params: {
					page: 1,
					limit: 10
				}
			});
		}
	}

	function businessRequestReload(user_id: number, company: any) {
		const isSBusinessRequestPage = location.pathname.includes('business-requests');
		if (isSBusinessRequestPage && userData?.id === user_id) {
			reloadEntityAll({
				url: `/${companyId}/my/company/user_connection_requests/`,
				entity: 'incomingRequest',
				name: `AllIncomingRequest-${companyId}`,
				replaceIds: true,
				params: {
					page: 1,
					limit: 10
				}
			});
		}

		// const isClientDetailsPage = location.pathname.includes('/clients-management/clients/');

		// if (isClientDetailsPage && company?.id === company?.company?.id) {
		// 	reloadEntityAll({
		// 		url: `/${companyId}/my/company/clients/${clientId}/employee/`,
		// 		entity: 'client-users',
		// 		name: `AllClientUser-${clientId}`,
		// 		replaceIds: true,
		// 		params: {
		// 			page: 1,
		// 			limit: 10
		// 		}
		// 	});
		// }
	}

	function handleBusinessTransfer(company: any, user: any) {
		if (companyId === company.id) {
			const changedCompanies = companies.map((comp: Company) => {
				if (comp.company.id === company.id) {
					return {
						...comp,
						company: {
							...comp.company,
							owner: user
						}
					};
				} else {
					return comp;
				}
			});
			dispatch(userActions.loadUserCompaniesSuccess(changedCompanies));
			const selectedBusiness = {
				...companyInRedux,
				company: { ...companyInRedux?.company, owner: user }
			};
			//@ts-ignore
			dispatch(SetSelectedUserCompany(selectedBusiness));
			storage.set('company', JSON.stringify(selectedBusiness));
			reloadEntityAll({
				entity: 'staffEmployee',
				name: `staffEmployee-${companyId}`,
				url: `/${companyId}/employee/`,
				params: {
					page: 1,
					limit: 50
				}
			});
		}
	}

	function noUnreadLeftForCompany(company_id: string) {
		const changedCompanies = companies.map((comp: Company) => {
			if (comp.company.id === Number(company_id)) {
				return {
					...comp,
					company: {
						...comp.company,
						unread_cases_count: 0
					}
				};
			} else {
				return comp;
			}
		});
		dispatch(userActions.loadUserCompaniesSuccess(changedCompanies));
	}

	type FilterOptions = {
		departmentId: number;
		priority: PriorityStrings;
		statusId: number;
		messageType: string;
		taggedUserIds: number[];
		subDepartmentId: number;
		entityId: number;
	};

	//The function below check only status and priority filters. But we may add check for other filter in the future.
	//But in this case, we may need more fields to be added to message object received in user socket.
	function checkCaseForFiltering({
		priority,
		statusId,
		messageType,
		taggedUserIds = [],
		departmentId,
		subDepartmentId,
		entityId
	}: FilterOptions) {
		const selectedFilterTemplate = selectedCaseFilter[caseType];

		const statusFilter = selectedFilterTemplate?.filter_json?.filter_status || [];
		const priorityFilter = (selectedFilterTemplate?.filter_json?.priority || []) as PriorityType[];
		//const assigneeFilter = selectedFilterTemplate?.filter_json?.query_web?.assignees || '';
		const taggedFilter = selectedFilterTemplate?.filter_json?.tagged;
		const departmentFilter = selectedFilterTemplate?.filter_json?.department || [];
		const subDepartmentFilter = selectedFilterTemplate?.filter_json?.sub_department || [];

		const hasPriorityFilter = priorityFilter.length;
		const hasStatusFilter = statusFilter.length;
		const hasDepartmentFilter = departmentFilter.length;
		const hasSubDepartmentFilter = subDepartmentFilter.length;
		const hasScheduledFilter = selectedFilterTemplate?.filter_json?.scheduled ?? false;

		const matchedStatus = statusFilter.find((status: { id: number }) => status.id === statusId);
		const matchedPriority = priorityFilter.find((priorityItem) => priorityItem.code === priority);
		//const matchedAssignee = assigneeFilter.includes(toUser?.id?.toString());
		const matchedTaggedUser = (taggedUserIds ?? []).includes(userData?.id);
		const matchedDepartment = departmentFilter.find(
			(department: { id: number }) => department.id === departmentId
		);
		const matchedSubDepartment = subDepartmentFilter.find(
			(subDepartment: { id: number }) => subDepartment.id === subDepartmentId
		);
		const hasCaseScheduledMessages = !!scheduledMessageIds?.[entityId]?.length;

		const isAndType = filterType === 'and';
		const isOrType = filterType === 'or';

		const filtersConditions = [];
		const filtersResults = [];

		if (hasStatusFilter) {
			filtersConditions.push(statusFilter);
			filtersResults.push(matchedStatus);
		}

		if (hasPriorityFilter) {
			filtersConditions.push(priorityFilter);
			filtersResults.push(matchedPriority);
		}

		/* if (assigneeFilter.length) {
			filtersConditions.push(assigneeFilter);
			filtersResults.push(matchedAssignee);
		} */

		if (taggedFilter) {
			filtersConditions.push([taggedFilter]);
			filtersResults.push(matchedTaggedUser);
		}

		if (hasScheduledFilter) {
			filtersConditions.push([hasScheduledFilter]);
			filtersResults.push(hasCaseScheduledMessages);
		}

		if (hasSubDepartmentFilter) {
			filtersConditions.push(subDepartmentFilter);
			filtersResults.push(matchedSubDepartment);
		} else if (hasDepartmentFilter) {
			filtersConditions.push(departmentFilter);
			filtersResults.push(matchedDepartment);
		}

		if (!selectedFilterTemplate || filtersConditions.every((filter) => !filter.length)) {
			return true;
		}
		//Checks if there is filter template where both filter by status and priority, assigned to me are selected
		//In this use case, message should satisfy all filters
		if (isAndType && filtersConditions.every((filter) => filter.length)) {
			const filterResult = filtersResults.every((result) => !!result);

			return filterResult;
		}

		if (isOrType && filtersConditions.some((filter) => !!filter)) {
			return filtersResults.some((result) => !!result);
		}

		//Checks  only for status matching
		if (hasStatusFilter) {
			return matchedStatus;
		}
		//Checks  only for priority matching
		if (hasPriorityFilter) {
			return matchedPriority;
		}

		if (hasScheduledFilter) {
			return hasCaseScheduledMessages;
		}

		//Checks only for sub-department matching
		if (hasSubDepartmentFilter) {
			return matchedSubDepartment;
		}
		//checks only for department matching
		if (hasDepartmentFilter) {
			return matchedDepartment;
		}

		if (taggedFilter && !matchedTaggedUser) {
			return false;
		}

		/* if (assigneeFilter && !matchedAssignee) {
			return false;
		} */

		return true;
	}

	return {
		updateListUnread,
		showNewMessageNotification,
		changeStatus,
		showStatusChangeNotification,
		changePriority,
		showPriorityChangeNotification,
		permissionChangeUpdate,
		updateGroupChatList,
		showCaseCreationNotification,
		fetchSingleCase,
		reloadCaseOrGroupChat,
		draftMessageHandler,
		showStaffInvitationNotification,
		getEntityData,
		moveEntityIdToBeginning,
		liftEntityToTop,
		connectionRequestHandler,
		casePinHandler,
		updateStatusName,
		handleAssigneeUpdated,
		imsPinUnpinHandler,
		handleStaffEditMessage,
		incrementMsgUnread,
		fetchCasesByStatus,
		checkCaseListUpdate,
		handleBusinessTransfer,
		staffListReload,
		businessRequestReload,
		checkCaseForFiltering,
		noUnreadLeftForCompany,
		removeCaseFromUI
	};
}

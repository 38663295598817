import { useCallback, useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import Selectors from 'modules/entity/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { IMessage } from 'shared/interfaces';
import { ChatEntityType, useAppState } from 'shared/state';
import {
	useChatFunctions,
	useChatMessage,
	useFilterTemplateState,
	useQueryParams,
	useUnreadCount,
	useUpdateEntities
} from 'shared/hooks/index';
import { useChatContext, useChatInputContext, useChatSocket } from 'shared/contexts';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { decrementEntityTotal, resetEntityList } from 'modules/entity/actions';
import { useSocketHelpers } from '../../userSocket/hooks/useSocketHelpers';
import { useEntityForm } from 'modules/entity/hooks/useEntityForm';
import { getChatMessagesEntityName } from '../../components/Chat/utils';
import { casesEventChannel } from 'eventChannels/cases';
import Actions from 'store/actions';

const getAll = Selectors.getAll();
const resetEntitiesList = Actions.entities.resetEntitiesList;

type UpdateEntityPage = {
	dir: 'next' | 'previous';
	uuid: string;
} | null;

type ScrollIntoElementType = {
	msgId: number;
	messageIndex: number;
	scrollType?: 'auto' | 'smooth';
	blink?: boolean;
	scrollAlign?: 'start' | 'center' | 'end';
};

export function useChatMessagesList() {
	const isInitialReadActionSent = useRef(false);
	const { decrementUnread } = useChatFunctions();
	const { sendJsonMessage } = useChatSocket();
	const { userData } = useAuth();
	const { isDelayedMessage } = useChatInputContext();
	const {
		setIsLastPageFetched,
		setIsFirstPageFetched,
		isLastPageFetched,
		isFirstPageFetched,
		setLastReadMessageId,
		virtuosoRef
	} = useChatContext();
	const {
		groupMessageSearchQuery,
		setIsLastPageLoadedTriggered,
		isLastPageLoadedTriggered,
		isStaff
	} = useAppState();
	const { getEntityData } = useSocketHelpers();

	const { fetchCompanyAllUnreadCounts } = useUnreadCount();

	const [updateEntityPage, setUpdateEntityPage] = useState<UpdateEntityPage>(null);
	const paginationDepsRef = useRef<{
		isMessageHasBeenIndicated: boolean;
		isSetSearchReply: boolean;
		scrollToMsgId: null | number;
		groupMessageSearchQuery: string;
		isFirstPageFetched: boolean;
		direction: string | null;
		totalMessagesCount: number;
		cursorTop: number;
	}>({
		isMessageHasBeenIndicated: false,
		isSetSearchReply: false,
		scrollToMsgId: null,
		groupMessageSearchQuery: '',
		isFirstPageFetched: false,
		direction: null,
		totalMessagesCount: 0,
		cursorTop: 0
	});

	const {
		companyId,
		appDeviceUuid,
		groupsUnreadCountData,
		setGroupUnreadMessage,
		unreadMessagesCount,
		setUnreadMessagesCount,
		setEntityPage,
		entityPage,
		searchQuery,
		selectedCaseFilter,
		caseType
	} = useAppState();

	const { query, removeOneQueryParam } = useQueryParams();
	const searchedMessageId = query?.searchedMessageId;
	const statusId = Number(query?.status);
	const lastReadMsgIdFromQuery = (query.unread && Number(query.unread)) || null;
	const [cursor, setCursor] = useState<null | number>(lastReadMsgIdFromQuery);
	const dispatch = useDispatch();

	const { itShouldSatisfyActiveFilter } = useFilterTemplateState();

	const {
		entityId,
		direction,
		setDirection,
		setAllowToLoad,
		scrollToMsgId,
		setScrollToMsgId,
		setIsSetSearchReply,
		setReplyLoading,
		entityType,
		setScrollPosition,
		isSetSearchReply,
		setIsMsgHasBeenIndicated,
		isMsgHasBeenIndicated,
		isScheduledChat,
		setBottomLoading
	} = useChatContext();
	const { sendReadAction } = useChatMessage({
		sendJsonMessage,
		entityId,
		entityType
	});

	const noUnreadLeftForThisChat =
		entityType === 'case' ? !unreadMessagesCount?.[entityId] : !groupsUnreadCountData[entityId];

	const [localEntityId, setLocalEntityId] = useState(entityId);
	const entityName = getChatMessagesEntityName({
		isScheduled: isScheduledChat,
		entityId,
		entityType
	});
	const messagesUrl = isScheduledChat
		? '/scheduled_messages/'
		: '/messages/massive-load/cursor-based/';

	const { entityName: caseEntityName } = getEntityData('case', statusId);

	const { handleLocalDelete } = useEntityForm({
		deleteData: true,
		method: 'delete',
		url: '',
		entity: 'cases',
		name: caseEntityName,
		cb: {
			success: () => {},
			error: () => {},
			finally: () => {}
		}
	});

	const messages = useSelector((state) =>
		getAll(state, {
			entity: `${entityType}Messages`,
			name: entityName
		})
	);

	const { items: messagesArray } = messages;

	const { updateEntities } = useUpdateEntities();

	//Sending read action when load chat from rest initially
	useEffect(() => {
		//fix showing unread count and scroll to bottom button when there is less msg to scrolling
		if (messages.items.length) {
			const msgWrpNodeList = document.querySelectorAll('.msg-wrp');

			const msgWrpNodeParent = document.querySelector('.msg-wrp')?.closest('div:not(div[id])');
			const messagesHeight = Array.from(msgWrpNodeList).reduce((acc: any, item: any) => {
				return acc + item.offsetHeight;
			}, 0);

			//if messages total height is less than wrapper block height(it means there is no vertical scroll)
			if (msgWrpNodeParent && messagesHeight < msgWrpNodeParent.clientHeight) {
				setScrollPosition(true);
			}
		}
	}, [messages, isLastPageFetched]);

	//Updating cursor
	useEffect(() => {
		const msgList = messages.items;
		const cursorTail = msgList[0]?.id;
		const cursorHead = msgList[msgList.length - 1]?.id ?? msgList[msgList.length - 1]?.message_id;
		setEntityPage({
			entityId,
			cursorTop: cursorTail,
			cursorBot: cursorHead,
			isLoadedRepliedMessage: entityPage[entityId]?.isLoadedRepliedMessage
		});
	}, [messages]);

	function removeUnreadCountFromCaseList() {
		if (entityType === 'ims' && entityId in groupsUnreadCountData) {
			const entityIds = { ...groupsUnreadCountData };
			delete entityIds[entityId];
			setGroupUnreadMessage(entityIds);
			return;
		}
		if (unreadMessagesCount && entityType === 'case' && entityId in unreadMessagesCount) {
			const entityIds = { ...unreadMessagesCount };
			delete entityIds[entityId];
			setUnreadMessagesCount(entityIds);
		}
	}

	//When we open case or switch case
	useEffect(() => {
		setCursor(lastReadMsgIdFromQuery || null);
		setDirection(null);
		setAllowToLoad(false);

		//TODO: watch later side effects
		//Set IsFirstPageFetched and isLastPageFetched state to reset pagination  when switching case
		setIsFirstPageFetched(false);
		setIsLastPageFetched(false);

		setLocalEntityId(entityId);
		if (entityId in entityPage) {
			const isLoadedRepliedMsgs = entityPage[entityId].isLoadedRepliedMessage;
			const calculatedEntityPage = {
				entityId,
				cursorTop: isLoadedRepliedMsgs ? null : entityPage[entityId].cursorTop,
				cursorBot: isLoadedRepliedMsgs ? null : entityPage[entityId].cursorBot,
				isLoadedRepliedMessage: isLoadedRepliedMsgs
			};
			setEntityPage(calculatedEntityPage);
		} else {
			setEntityPage({
				entityId,
				cursorTop: null,
				cursorBot: null,
				isLoadedRepliedMessage: false
			});
		}

		// eslint-disable-next-line @typescript-eslint/no-empty-function
		return () => {
			//reset ref variable
			isInitialReadActionSent.current = false;

			//Fix: Reset messages on exiting to provide correct loading when open case  again without hard refreshing if there are unread messages
			//todo: find out whether we need this function call for group chat
			dispatch(resetEntityList(`${entityType}Messages`, entityName));
			dispatch(resetEntitiesList({ entity: `${entityType}Messages` }));

			const selectedFilterTemplate = selectedCaseFilter[caseType];

			if (selectedFilterTemplate) {
				casesEventChannel.emit('refetchCasesByStatus', statusId);
			}
		};
	}, [entityId, searchedMessageId]);

	//decrement total unread count if unread filter is active when close case or switch to another case
	// remove read case if unread filter is selected when close case or switch to another case
	useEffect(() => {
		return () => {
			if (entityType === 'case' && itShouldSatisfyActiveFilter) {
				//Remove case from UI if there is selected case filter template which has unread filter
				handleLocalDelete(entityId, 'cases', caseEntityName);

				//decrement total
				dispatch(decrementEntityTotal('cases', caseEntityName));
			}
		};
	}, [itShouldSatisfyActiveFilter, caseEntityName, entityType]);

	useEffect(() => {
		const cursorHead = messagesArray[messagesArray.length - 1]?.id;
		const cursorTail = messagesArray[0]?.id;

		if (updateEntityPage) {
			if (updateEntityPage.dir === 'next') {
				setEntityPage(
					{
						entityId,
						cursorTop: cursorTail,
						cursorBot: cursorHead,
						isLoadedRepliedMessage: entityPage[entityId].isLoadedRepliedMessage
					},
					'on page Change'
				);
				return;
			}
			setEntityPage(
				{
					entityId,
					cursorTop: cursorTail,
					cursorBot: cursorHead,
					isLoadedRepliedMessage: entityPage[entityId].isLoadedRepliedMessage
				},
				'on page Change'
			);
		}
	}, [updateEntityPage]);

	useEffect(() => {
		if (searchedMessageId) {
			setScrollToMsgId(Number(searchedMessageId));
			paginationDepsRef.current.scrollToMsgId = Number(searchedMessageId);
		}
	}, [searchedMessageId]);

	const cursorBot = Number(get(entityPage, `${entityId}.cursorBot`));
	const cursorTop = Number(get(entityPage, `${entityId}.cursorTop`));
	const isLoadedRepliedMessage = get(entityPage, `${entityId}.isLoadedRepliedMessage`);

	const handlePageLoading = (isVisible: boolean, direction: string, entityId: number) => {
		//TODO: REMOVE IT NOT NECESSARY
		//remove unread from query. we don't need it now
		if (lastReadMsgIdFromQuery) {
			removeOneQueryParam('unread');
		}

		if (isVisible) {
			let apiPage;
			setDirection(direction);
			if (direction === 'next') {
				apiPage = Number(get(entityPage, `${entityId}.cursorTop`));
			} else {
				apiPage = Number(get(entityPage, `${entityId}.cursorBot`));
			}
			setAllowToLoad(false);

			//For Messages search mode. To ensure pagination work after search indication
			if (isLastPageLoadedTriggered) setIsLastPageLoadedTriggered(false);

			setIsSetSearchReply(false);
			setCursor(apiPage ? apiPage : null);
		}
		//There were some deprecated TODOS. View git history to get more information
	};

	useEffect(() => {
		if (scrollToMsgId) {
			scrollToRepliedMsg(scrollToMsgId, localEntityId);
			setIsLastPageFetched(false);
		}
	}, [scrollToMsgId]);

	useEffect(() => {
		if (isDelayedMessage) {
			try {
				loadPage1();
			} catch (e) {
				console.error('error', e);
			}
		}
	}, [isDelayedMessage]);

	function loadPage1() {
		setEntityPage({
			entityId,
			cursorTop: null,
			cursorBot: null,
			isLoadedRepliedMessage: true //to delete previous pages and load messages page from beginning
		});
		prepareForLoadingCustomMessagePage({ msgId: null });
		setPagesForCustomMessageLoading({ currentPage: null, goToLastPage: true });
		setDirection(null);
	}

	/*
  The reason of setting some state variables to ref object is to access updated
   values in EntityContainer onInit function. Because onInit function "remembers" initial values on "did mount lifecycle"
  * */
	useEffect(() => {
		paginationDepsRef.current.isMessageHasBeenIndicated = isMsgHasBeenIndicated;
		paginationDepsRef.current.isSetSearchReply = isSetSearchReply;
		paginationDepsRef.current.isFirstPageFetched = isFirstPageFetched;
		paginationDepsRef.current.groupMessageSearchQuery = groupMessageSearchQuery;
		paginationDepsRef.current.direction = direction;
		paginationDepsRef.current.totalMessagesCount = messages?.items.length;
		paginationDepsRef.current.cursorTop = cursorTop;
	}, [
		isMsgHasBeenIndicated,
		isSetSearchReply,
		scrollToMsgId,
		isFirstPageFetched,
		groupMessageSearchQuery,
		direction,
		messages?.items,
		cursorTop
	]);

	//Scroll into message and indicate message(DOM event action) || scrollIntoRepliedMessage(old name)
	const scrollIntoElement = useCallback(
		({
			msgId,
			messageIndex,
			scrollType = 'smooth',
			blink = true,
			scrollAlign = 'center'
		}: ScrollIntoElementType) => {
			//if there will be issues with indicating scrolled to message or pagination issues after scrolling
			// remove setTimeout  wrapping scrollIntoView function and wrap all inner stuff in "done" method with setTimeout
			setTimeout(() => {
				virtuosoRef?.current?.scrollIntoView({
					index: messageIndex,
					behavior: scrollType,
					align: scrollAlign,
					done: () => {
						const messageIdElement = msgId ? document.querySelector(`#message-${msgId}`) : null;
						if (blink) {
							messageIdElement?.classList.add('indicate');
						}

						setTimeout(() => {
							if (messageIdElement && messageIdElement.classList.contains('indicate')) {
								messageIdElement.classList.remove('indicate');
							}
							setIsMsgHasBeenIndicated(false);
							setAllowToLoad(true);
							setBottomLoading(false);

							if (!noUnreadLeftForThisChat) {
								updateUnreadCount();
							}
						}, 3000);
					}
				});
			}, 0);

			if (scrollToMsgId) {
				setScrollToMsgId(null);
				paginationDepsRef.current.scrollToMsgId = null;
			}
		},
		[scrollToMsgId, messages, noUnreadLeftForThisChat]
	);

	function updateUnreadCount() {
		if (entityType === 'case') {
			fetchCompanyAllUnreadCounts({
				caseUnread: true
			});
		} else {
			fetchCompanyAllUnreadCounts({
				groupUnread: true
			});
		}
	}

	//Scroll to replied message handler
	// eslint-disable-next-line no-unused-vars
	const scrollToRepliedMsg = useCallback(
		(msgId, entityId) => {
			const isMessageLoaded = messages.items.find(
				(msg: IMessage) => msg?.id === msgId || msg?.message_id === msgId
			);

			const messageIndex = messages.items.findIndex(
				(msg: IMessage) => msg?.id === msgId || msg?.message_id === msgId
			);

			//!searchQuery.length - to indicated searched message we always refetched msg containing page
			//https://www.youtube.com/watch?v=0Id3DwBi_l4
			if (isMessageLoaded && !searchQuery) {
				scrollIntoElement({ msgId, messageIndex });
			} else {
				/*
				 *	setIsMsgHasBeenIndicated - is necessary not to allow loading prev or next page during message indication
				 *
				 * */
				setIsMsgHasBeenIndicated(true);
				prepareForLoadingCustomMessagePage({ msgId });
				handleCustomMessageLoad(msgId);
			}
		},
		[messages, scrollIntoElement]
	);

	function prepareForLoadingCustomMessagePage({ msgId = null }) {
		setIsSetSearchReply(true);
		//Show spinner when click to replied message
		setReplyLoading(true);

		//We must block LoadMoreVisible to again till current request finishes
		setAllowToLoad(false);
		//This is needed to load messages from beginning if we load replied message and will not scroll down till bottom
		if (msgId) {
			setEntityPage({
				entityId,
				cursorTop: entityPage[entityId].cursorTop || null,
				cursorBot: entityPage[entityId].cursorBot || null,
				isLoadedRepliedMessage: true
			});
		}
	}

	/*
  Loading message located page of searched(replied) message or
  load to most new messages page when we click to scrollToBottom button
* */

	function handleCustomMessageLoad(msgId: number) {
		//There was logic about showing Notification when any message has no custom_uuid.
		//the logic was removed due to we changed the way to fetch message by cursor.
		//And now do not need to call api to know the page which includes searched message
		//For detailed information check git history
		setPagesForCustomMessageLoading({
			currentPage: msgId,
			goToLastPage: false
		});
	}

	function setPagesForCustomMessageLoading({
		currentPage,
		goToLastPage = false
	}: {
		currentPage: number | null;
		goToLastPage?: boolean;
	}) {
		setReplyLoading(false);
		setCursor(currentPage);
		if (!goToLastPage) {
			setEntityPage({
				entityId,
				cursorTop: null,
				cursorBot: null,
				isLoadedRepliedMessage: true
			});
		} else {
			//when immediately scroll to and load 1 page, we had to set isSetSearchReply:false to properly prepend
			//loaded previous page, because previous page replaces page 1 due to prependData: false and appendData:false
			setIsSetSearchReply(false); // Check this if any bug in chat search
			setEntityPage({
				entityId,
				cursorTop: null,
				cursorBot: null,
				isLoadedRepliedMessage: true
			});
		}
	}

	function messageVisibleHandler(isVisible: boolean, message: IMessage) {
		//Send read action only for realtime socket messages
		if (
			isVisible &&
			!message?.read_time &&
			message?.from_user?.id !== userData.id
			// && message?.message_id // message_id is not available when we fetch messages from REST API
		) {
			const lastMessage = getLastMessage(messages);
			const currMessageId = message.id || message.message_id;
			const lastMessageId = lastMessage.id || lastMessage.message_id;
			const isLastMessage = lastMessageId === currMessageId;

			sendReadAction({
				isLast: isLastMessage,
				entityType,
				entityId,
				companyId,
				messageId: currMessageId,
				appDeviceUuid
			});

			decrementUnread({
				entityType: message.entity_type as ChatEntityType,
				entityId: message.entity_id
			});

			setLastReadMessageId(currMessageId as number);

			//Immediately update last_read_message of case | group chat. 3279's fix.
			updateEntities({
				entity: message.entity_type === 'case' ? 'cases' : 'ims-chats',
				entityId: message.entity_id,
				updatingData: {
					last_read_message: currMessageId
				}
			});

			// //Immediately update read_time field of message object in Redux
			updateEntities({
				entity: `${entityType}Messages`,
				entityId: message.custom_uuid || 0,
				updatingData: {
					read_time: Date.now()
				}
			});
		}
	}

	function getLastMessage(messages: any) {
		const messagesLength = messages.items.length;
		return messages.items[messagesLength - 1];
	}

	const handleFollowOutput = useCallback((lastReadMessageId: number | null) => {
		return function (isAtBotttom: boolean) {
			if (lastReadMessageId || !isAtBotttom) {
				return false;
			}

			return 'smooth';
		};
	}, []);

	return {
		cursorBot,
		cursorTop,
		handlePageLoading,
		localEntityId,
		scrollIntoElement,
		isLoadedRepliedMessage,
		messages,
		messageVisibleHandler,
		setUpdateEntityPage,
		setEntityPage,
		entityPage,
		searchedMessageId,
		paginationDepsRef,
		cursor,
		isLastPageFetched,
		setIsLastPageFetched,
		isFirstPageFetched,
		setIsFirstPageFetched,
		totalMessagesCount: messages?.items.length,
		removeUnreadCountFromCaseList,
		messagesUrl,
		handleFollowOutput
	};
}

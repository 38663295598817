import React from 'react';
import { Button, DialogActions, DialogTitle, WhiteModal } from './Elements';
import Loader from './Loader';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

type Props = {
	toggleHandler: () => void;
	isOpen: boolean;
	featureHandler: (args: any) => void;
	hasLoader?: boolean;
	isLoading?: boolean;
	title: string | React.ReactNode;
	maxWidth?: number;
	titleClassNames?: string;
	icon?: React.ReactNode;
	type: 'error' | 'success';
	secondaryBtnText?: string;
	primaryBtnText: string;
	helperText?: string;
};

export function NewConfirmModal({
	toggleHandler,
	isOpen,
	featureHandler,
	isLoading = false,
	hasLoader = false,
	maxWidth,
	title,
	titleClassNames,
	icon,
	secondaryBtnText,
	primaryBtnText,
	type,
	helperText = '',
	...rest
}: Props) {
	const { t } = useTranslation();
	return (
		<>
			{isOpen ? (
				<WhiteModal handleModalOpen={toggleHandler} open={isOpen} maxWidth={maxWidth}>
					<DialogTitle id="alert-dialog-title" className={titleClassNames}>
						<div
							className={clsx(
								'mx-auto mb-2.5 flex h-12 w-12 place-content-center items-center justify-center rounded-full p-2',
								{
									'bg-red-500/10 text-kred-red': type === 'error',
									'bg-kgrey-bg text-primary': type === 'success'
								}
							)}
						>
							{icon}
						</div>
						<h3 className="text-center font-medium">{title}</h3>
						<p className="py-2.5 text-sm">{helperText}</p>
					</DialogTitle>
					<DialogActions>
						<Button onClick={toggleHandler} autoFocus variant="contained">
							{secondaryBtnText ?? t('cancel')}
						</Button>
						<Button
							onClick={() => featureHandler(rest)}
							variant="contained"
							color={type === 'error' ? 'secondary' : 'primary'}
							classes={{
								root: clsx('!mx-1.5', { '!bg-primary-dark !text-white': type === 'success' })
							}}
						>
							{primaryBtnText ?? t('yes')}
						</Button>
					</DialogActions>
					{hasLoader ? (
						<>
							{isLoading && (
								<div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-70">
									<Loader />
								</div>
							)}
						</>
					) : null}
				</WhiteModal>
			) : null}
		</>
	);
}

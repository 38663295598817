import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import Mentions from 'rc-mentions';
import { useMentionApi } from '../../hooks/useMentionApi';
import { useChatInput, useChatMessage, useDraftMessage } from 'shared/hooks';
import { useChatContext, useChatInputContext, useChatSocket } from 'shared/contexts';
import { sanitizeMentionMsg } from 'shared/services';
import clsx from 'clsx';
import { MentionsRef } from 'rc-mentions/es/Mentions';
import { useTranslation } from 'react-i18next';

type Props = {
	handleFormSubmit: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
	isAllowedSendMsg: boolean;
	isInputDisabled: boolean;
	isComponentVisible: boolean;
	handleDraft: (type: 'chat' | 'user', draftMessage?: string) => void;
};

export const ChatMentionInput = forwardRef<MentionsRef, Props>(function ChatMentionInput(
	props: Props,
	ref
) {
	const { handleFormSubmit, isAllowedSendMsg, isInputDisabled, isComponentVisible, handleDraft } =
		props;
	const { t } = useTranslation();

	const { onSearch, onOptionSelect, PREFIX, mentionUsers } = useMentionApi();
	const { handlePressKey, inputRef } = useChatInput(handleFormSubmit);
	const { message, setMessage, editingMessage, reply } = useChatInputContext();
	const { setLS, getLS, saveDraft } = useDraftMessage();
	const { entityId, entityType, isScheduledChat } = useChatContext();
	const { sendJsonMessage } = useChatSocket();
	const { sendTypingInfo, areRestMessagesLoaded } = useChatMessage({
		sendJsonMessage,
		entityId,
		entityType
	});
	const canSendTypingRef = useRef(true);
	const throttleTime = 2000;

	const typingNew = useCallback(() => {
		let timer: number | ReturnType<typeof setTimeout> = 0;
		if (canSendTypingRef.current) {
			clearTimeout(timer);
			sendTypingInfo({ entity_type: entityType, type: 'typing', entity_id: entityId });

			timer = setTimeout(function () {
				canSendTypingRef.current = true;
			}, throttleTime);
		}

		canSendTypingRef.current = false;
	}, [sendTypingInfo, entityType, entityId]);

	useImperativeHandle(ref, () => inputRef.current!, []);

	useEffect(() => {
		if (areRestMessagesLoaded) {
			setTimeout(() => {
				inputRef?.current?.focus();
			}, 0);
		}
	}, [areRestMessagesLoaded]);

	//3652- if input value contains only prefix, need to remove it between switching cases
	//else need to save it as draft between switching cases
	useEffect(() => {
		return () => {
			if (message === PREFIX) {
				setMessage('');
				return;
			}

			const draftMessageInLS = getLS(entityId);

			if (draftMessageInLS?.text?.length && !isComponentVisible && !isScheduledChat) {
				saveDraft('user', {
					message: draftMessageInLS?.text,
					entityId,
					editingMessage: draftMessageInLS?.editing_id
						? { id: draftMessageInLS?.editing_id as number }
						: null,
					reply: draftMessageInLS?.reply_to ? { id: draftMessageInLS?.reply_to as number } : null
				});
			}
		};
	}, [entityId]);

	function handleOnBlur(event: React.FocusEvent<HTMLTextAreaElement>) {
		const value = event.target.value;
		/*
        we should remove prefix(@) if there is nothing except prefix.
        Because it is not possible(library limitation) to show suggestions dropdown on input focus
        if prefix is draft. So we remove it so that user does not get confused why dropdown is not showing up.
        */
		if (value === PREFIX) {
			setMessage('');
			return;
		}

		if (message.length && !isComponentVisible && !isScheduledChat) {
			handleDraft('chat', message);
		}
	}

	function onChangeHandler(text: string) {
		setMessage(text);
		setLS({
			payload: {
				message: text,
				editingMessage,
				reply,
				entityId
			}
		});
	}

	function onKeyDownHandler(e: React.KeyboardEvent<HTMLTextAreaElement>) {
		handlePressKey(e);
		typingNew();
	}

	const computedPlaceholder = `${
		!isAllowedSendMsg
			? t('no_permission_to_write_in_chat')
			: `${
					isInputDisabled ? t('loading_chat') : `${isMobile ? t('type') : t('type_your_message')}`
			  }`
	}`;

	return (
		<>
			<Mentions
				onSelect={onOptionSelect}
				onSearch={onSearch}
				style={{ width: '100%' }}
				autoFocus
				options={mentionUsers}
				placement="top"
				prefix={PREFIX}
				dropdownClassName={clsx('z-20', { hidden: !message.length })}
				//@ts-ignore
				ref={inputRef}
				placeholder={computedPlaceholder}
				value={sanitizeMentionMsg(message)}
				onChange={onChangeHandler}
				className="relative top-1 w-full resize-none bg-kgrey-inputBg p-2 text-sm placeholder-[#747F8D] outline-none transition-all dark:bg-transparent dark:text-white"
				disabled={isInputDisabled}
				onKeyDown={onKeyDownHandler}
				autoComplete="off"
				onBlur={handleOnBlur}
				autoSize={{ maxRows: isMobile ? 2 : 5 }}
			/>
		</>
	);
});

/**@jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useChatHelpers, useDraftMessage } from 'shared/hooks';
import { useChatContext, useChatInputContext } from 'shared/contexts';
import { HtmlParser } from 'shared/components';
import { useAppState } from '../../../state';
import { CgCloseO } from 'react-icons/cg';
import { RiReplyFill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { convertToLocalizationKey } from 'shared/services/utils/convertToLocalizationKey';

type Props = {
	handleEditReplyCancelling: (isCancelling: boolean) => void;
};

export function RepliedMessage({ handleEditReplyCancelling }: Props) {
	const { renderTextMessage } = useChatHelpers();
	const { entityId, entityType } = useChatContext();
	const { reply, setReply } = useChatInputContext();
	const { activeDraft, setActiveDraft } = useAppState();
	const { t } = useTranslation();

	const { updateEntityDraft, getLS, updateLS } = useDraftMessage();

	const draftObject = getLS();

	const replyMessageClickHandler = () => {
		setReply(null);
		handleEditReplyCancelling(true);
		// removeLS(entityId, () => {
		// 	updateEntityDraft('reset');
		// 	// sendDeleteDraft();
		// });

		if (activeDraft || entityId in draftObject) {
			//4328 - when reply is canceled, reply should be null, message should be saved on input
			const activeDraftObjAfterReplyCanceled = activeDraft
				? { ...activeDraft, reply_to: null }
				: null;
			updateEntityDraft('add', {
				entityId,
				message: activeDraftObjAfterReplyCanceled?.text as string,
				reply: null,
				editingMessage: null
			});
			setActiveDraft(activeDraftObjAfterReplyCanceled);
			updateLS(entityId, entityType, { reply_to: null });
			//removeLS(entityId);
			//sendDeleteDraft();
		}
	};

	return (
		<>
			{reply ? (
				<div className="relative z-10 flex w-full items-center overflow-hidden rounded-t-lg bg-white p-2 dark:bg-kdark-dark333">
					<div className="icon dark:text-white">
						<RiReplyFill />
					</div>
					<div
						className="px-3"
						css={css`
							width: calc(100% - 32px);
						`}
					>
						<div className="msg-author text-xs italic text-kgrey">
							{` ${reply.from_user_first_name} ${reply.from_user_last_name}:`}
						</div>
						<div className="msg-itself max-h-6 truncate text-sm dark:text-white">
							<span>
								{reply.text
									? renderTextMessage(reply?.text.trim(), HtmlParser)
									: t(convertToLocalizationKey(`${reply.file_type}_message`))}
							</span>
						</div>
					</div>
					<div
						className="close flex-shrink-0 cursor-pointer transition hover:text-primary dark:text-white dark:hover:text-primary-dark"
						onClick={replyMessageClickHandler}
					>
						<CgCloseO />
					</div>
				</div>
			) : null}
		</>
	);
}

import { FormLabel, InputAdornment, TextField, makeStyles } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
	form: { setFieldValue: any };
	field: { name: string };
	onChange: () => void;
	dateValue: string;
	label: string;
	required?: boolean;
};

const useStyles = makeStyles(() => ({
	textField: {
		borderRadius: '12px', // Rounded corners
		//padding: '10px',
		paddingBottom: 0,
		width: '100%',
		'& input': {
			padding: '12px'
		},
		'& .MuiInputBase-adornedEnd': {
			paddingRight: '5px'
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: '12px'
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderWidth: 0
		}
	},
	inputLabel: {
		color: '#9e9e9e', // Placeholder color
		fontSize: '16px'
	}
}));

export function CustomDatePicker(props: Props) {
	const { t } = useTranslation();
	const classes = useStyles();

	const {
		form: { setFieldValue },
		field: { name },
		onChange,
		dateValue = '',
		required = false,
		label,
		...rest
	} = props;

	const [selectedDate, handleDateChange] = React.useState<any>(
		!isEmpty(dateValue) ? new Date(dateValue) : null
	);

	useEffect(() => {
		!isEmpty(dateValue) && handleDateChange(new Date(dateValue));
	}, [dateValue]);
	return (
		<div className="mb-4 flex flex-col">
			{label ? (
				<label
					className={clsx('ml-2 mb-2 block text-sm font-light text-kgrey-menuText')}
					htmlFor={name}
				>
					<span>{label}</span>
					{required ? <sup>*</sup> : null}
				</label>
			) : null}
			<DatePicker
				okLabel={t('ok')}
				cancelLabel={t('cancel')}
				shouldDisableDate={(date) => (date ? date.toDate() > new Date() : false)}
				value={selectedDate}
				className="custom-date-picker"
				TextFieldComponent={(props) => (
					<TextField
						{...props}
						variant="outlined"
						className={clsx(classes.textField, 'border-none bg-kgrey-light-grey-1 dark:bg-kdark')}
						placeholder="dd/mm/yyyy"
						InputProps={{
							endAdornment: (
								<InputAdornment position="start">
									<CalendarToday fontSize={'small'} color="action" />
								</InputAdornment>
							)
						}}
						InputLabelProps={{
							shrink: true,
							className: classes.inputLabel
						}}
					/>
				)}
				//classes={{ root: classes.datePickerRoot }}
				// value={selectedDate}
				{...rest}
				onChange={
					onChange
						? (date) => {
								handleDateChange(date);
								setFieldValue(name, date);
								onChange();
						  }
						: (date) => {
								handleDateChange(date);
								setFieldValue(name, date);
						  }
				}
			/>
		</div>
	);
}

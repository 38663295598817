import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { GrClose } from 'react-icons/gr';
import IconButton from '@material-ui/core/IconButton';
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import { GrRotateLeft, GrRotateRight } from 'react-icons/gr';
import clsx from 'clsx';
import { useWhiteModal, useWindowSize } from 'shared/hooks';
import { PDFPasswordInputConfirm } from './PDFPasswordInputConfirm';
import Loader from 'shared/components/Loader';

type Props = {
	file: string;
	fileName: string | undefined;
	onClose: () => void;
};

const enum ErrorResponses {
	'INVALID_PASSWORD' = 'invalid_password'
}

export function PdfViewer({ file, fileName, onClose }: Props) {
	const [numPages, setNumPages] = React.useState<number | null>(null);
	const [isPdfLoaded, setIsPdfLoaded] = React.useState(false);
	const [error, setError] = useState<string | null>(null);
	const [showDocument, setShowDocument] = useState(true);
	const [password, setPassword] = useState<string | null>(null);
	const [zoom, setZoom] = useState(1);
	const [rotate, setRotate] = useState(0);
	const { height: windowHeight } = useWindowSize();
	const {
		WhiteModal,
		open: isOpenPasswordEnterModal,
		modalHandler: togglePasswordEnterModal
	} = useWhiteModal();
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (ref.current && windowHeight) {
			ref.current.style.height = `${windowHeight - 150}px`;
		}
	}, [windowHeight]);

	function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
		setNumPages(numPages);
		setIsPdfLoaded(true);
	}

	function zoomHandler(scaling: 'in' | 'out') {
		if (scaling === 'in') {
			setZoom((prev) => prev + 0.1);
		}

		if (scaling === 'out') {
			setZoom((prev) => (prev > 0.5 ? prev - 0.1 : 0.5));
		}
	}

	function rotateHandler(direction: 'left' | 'right') {
		if (direction === 'left') {
			setRotate((prevState) => {
				return prevState === 0 ? 270 : prevState - 90;
			});
		}
		if (direction === 'right') {
			setRotate((prevState) => {
				return prevState === 360 ? 90 : prevState + 90;
			});
		}
	}

	//4378 - if PDF document is protected with password, while loading document, react-pdf's Document will trigger this function to get password from user
	const onPasswordProtectedPDFLoaded = (
		callback: (password: string) => void,
		reason: string | number
	) => {
		function callbackProxy(password: string | null) {
			if (password === null) {
				setShowDocument(false);
			} else {
				callback(password);
			}
		}
		if (isPdfLoaded) return;

		switch (reason) {
			//value: 1 - Password required and password should be passed to the callback
			case 1: {
				if (!isOpenPasswordEnterModal && !password) {
					togglePasswordEnterModal();
				}
				callbackProxy(password);
				break;
			}
			//value: 2 - Entered password is invalid
			case 2: {
				setError(ErrorResponses.INVALID_PASSWORD);
				setShowDocument(false);
				togglePasswordEnterModal();

				break;
			}
		}
	};

	const passwordInputChangeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value);
	}, []);

	const cancelPasswordInputConfirmation = useCallback(() => {
		onClose();
	}, []);

	const confirmPasswordInputConfirmation = useCallback(() => {
		setShowDocument(true);
		setError(null);
		togglePasswordEnterModal();
	}, []);

	//file = `https://cors-anywhere.herokuapp.com/${file}`;

	return (
		<div>
			<div>{!isPdfLoaded && <Loader tiled />}</div>
			{showDocument ? (
				<React.Fragment>
					<div className="mb-8 flex items-center justify-between">
						<h2 className="font-bold">
							{fileName}
							<a
								href={file}
								download
								className="ml-2 text-sm font-light text-kgrey transition hover:text-primary"
							>
								( Download Pdf )
							</a>
						</h2>
						<div>
							<IconButton size="medium" onClick={() => rotateHandler('left')}>
								<GrRotateLeft />
							</IconButton>
							<IconButton size="medium" onClick={() => rotateHandler('right')}>
								<GrRotateRight />
							</IconButton>
						</div>
						<div>
							<IconButton size="medium" onClick={() => zoomHandler('out')}>
								<AiOutlineZoomOut />
							</IconButton>
							<IconButton size="medium" onClick={() => zoomHandler('in')}>
								<AiOutlineZoomIn />
							</IconButton>
						</div>
						<div
							onClick={onClose}
							style={{ zIndex: 1000 }}
							className="flex-centered h-8 w-8 cursor-pointer rounded-full border border-kgrey bg-kgrey-bg transition hover:border-primary hover:bg-primary-200 dark:bg-kgrey-icon-bg"
						>
							<GrClose className="hover:text-primary" />
						</div>
					</div>

					<Document
						file={file}
						onLoadSuccess={onDocumentLoadSuccess}
						onPassword={onPasswordProtectedPDFLoaded}
						externalLinkTarget="_blank"
						loading={<h2>Loading file... Please wait...</h2>}
						className={clsx('overflow-auto')}
						inputRef={ref}
					>
						{Array.from(new Array(numPages), (el, index) => (
							<React.Fragment key={index}>
								<Page
									key={`page_${index + 1}`}
									pageNumber={index + 1}
									loading="Loading page.."
									scale={zoom}
									rotate={rotate}
								/>
								<div className=" flex-centered">
									<p className="my-2 h-8 w-full w-8 rounded-full border border-kgrey text-center font-poppins text-xs leading-8 text-kgrey">
										{index + 1}
									</p>
								</div>
							</React.Fragment>
						))}
					</Document>
				</React.Fragment>
			) : null}
			<WhiteModal open={isOpenPasswordEnterModal} handleModalOpen={onClose}>
				<PDFPasswordInputConfirm
					onCancelBtnClicked={cancelPasswordInputConfirmation}
					onPasswordInputChange={passwordInputChangeHandler}
					onConfirmBtnClicked={confirmPasswordInputConfirmation}
					error={error}
				/>
			</WhiteModal>
		</div>
	);
}

import React, { useState, useEffect, forwardRef } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Upload from 'rc-upload';
import { nanoid } from 'nanoid';
import { useAppState } from 'shared/state';
import config from 'config';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { IRootState } from '../../interfaces';
import { getCompany } from '../../../modules/user/selectors';
import { FilesType } from '../../../pages/BusinessProfileMain/components/BusinessProfile/hooks/useBusinessProfile';
import { useTranslation } from 'react-i18next';

const { CancelToken } = axios;
let cancelAx: any;
let currFile: any;

type Props = {
	uploadMeta: any;
	setUploadMeta: any;
	cancelSending: any;
	setCancelSending: any;
	setFile: any;
	isFromPendingPage?: boolean;
	classNames?: string;
	isBusinessProfile?: boolean;
	field?: string;
	accept?: string;
};

export const FileUploader = forwardRef(
	(
		{
			uploadMeta,
			setUploadMeta,
			cancelSending,
			setCancelSending,
			setFile,
			isFromPendingPage = false,
			classNames = '',
			isBusinessProfile = false,
			field = '',
			accept
		}: // file
		Props,
		ref: any
	) => {
		const { t } = useTranslation();
		const [progress, setProgress] = useState<number | null>(null);
		const company = useSelector((state: IRootState) => getCompany(state));
		const { companyId } = useAppState();
		const { token } = useAuth();

		useEffect(() => {
			if (isBusinessProfile) {
				setUploadMeta({ progress, field });
				return;
			}
			setUploadMeta(progress);
		}, [progress]);

		useEffect(() => {
			if (cancelSending) {
				const errorMessage = 'Operation canceled by the user.';
				cancelAx(errorMessage);
			}
		}, [cancelSending]);

		const fileOnStart = (file: any) => {
			const reader = new FileReader();
			const imgFile = file;
			const uuid = nanoid();

			reader.onloadend = () => {
				if (isFromPendingPage) {
					setFile((prevState: any) => [
						{ id: uuid, name: imgFile.name, file: imgFile },
						...prevState
					]);
				}
			};

			reader.readAsDataURL(imgFile);

			currFile = {
				...file,
				uid: file.uid,
				type: file.type,
				name: file.name,
				key: file.key,
				size: file.size,
				lastModified: file.lastModified
			};
		};

		// eslint-disable-next-line no-shadow
		const fileOnSuccess = (response: any, _file: any, currFile: any) => {
			setProgress(null);
			if (isBusinessProfile) {
				setFile((prev: FilesType) => ({
					...prev,
					[field]: response
				}));
				return;
			}
			if (!isFromPendingPage) setFile(response);
		};

		const onProgress = ({ percent }: { percent: number }, file: any) => {
			setProgress(Number(percent));
		};

		const onError = (err: any) => {
			console.log('err', err);
			setCancelSending(false);
			setProgress(null);
		};

		const action = `${config.API_ROOT}/${
			isFromPendingPage ? company?.company.id : companyId
		}/upload/`;

		return (
			<div>
				<Upload
					// @ts-ignore
					beforeUpload={fileOnStart}
					accept={accept}
					ref={ref}
					customRequest={({
						file,
						filename,
						headers = {
							Authorization: `Token ${token}`
						}
					}) => {
						const formData = new FormData();
						if (typeof filename === 'string') {
							formData.append(filename, file);
						}
						axios
							.post(action, formData, {
								headers,
								onUploadProgress: ({ total, loaded }) => {
									onProgress(
										{ percent: Number(Math.round((loaded / total) * 100).toFixed(2)) },
										file
									);
								},
								cancelToken: new CancelToken((c) => {
									cancelAx = c;
								})
							})
							.then(({ data: response }) => {
								fileOnSuccess(response, file, currFile);
							})
							.catch((err) => {
								onError(err);
							});

						return {
							abort() {
								console.log('upload progress is aborted.');
							}
						};
					}}
				>
					<Button
						dir="ltr"
						variant="outlined"
						className={`pending-upload__btn ${classNames}`}
						color="default"
						endIcon={<CloudUploadIcon />}
					>
						{t('upload')}
					</Button>
				</Upload>
			</div>
		);
	}
);

FileUploader.displayName = 'FileUploader';

FileUploader.defaultProps = {
	isFromPendingPage: false,
	classNames: '',
	isBusinessProfile: false,
	field: ''
};

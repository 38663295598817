import React, { useRef, useState } from 'react';
import EntityForm from 'modules/entity/forms';
import { useAppState } from 'shared/state';
import { CreateCaseElements } from './CreateCaseElements';
import get from 'lodash/get';
import { default as dayjs } from 'dayjs';
import { ErrorBoundary } from 'shared/components';
import {
	createDataFieldsOnSubmit,
	exceptionMembersListHandler,
	getDepartmentPayload,
	getSubDepartmentPayload,
	membersListHandler
} from '../helpers';
import { useCaseCreateInitialRequests } from '../hooks';
import config from '../../../config';
import isEmpty from 'lodash/isEmpty';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { useNotistack, useQueryParams } from 'shared/hooks';
import { useEntityForm } from 'modules/entity/hooks/useEntityForm';
import { useSocketHelpers } from 'shared/userSocket/hooks/useSocketHelpers';
import { StatusType } from './Status';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

type Props = {
	modalHandler: () => void;
	hasToShowGuard: () => void;
	formValuesSetter: (fieldValues: string[]) => void;
};

export type TCreateCaseFormState = {
	department: Record<string, any>;
	data_fields: any[];
	data_fields_names: Record<string, any>;
	data_fields_errors: Record<string, any>;
	data_fields_names_persisted: Record<string, any>;
	isDateTimeSet: boolean;
	client_company: Record<string, any>;
	client_user: Record<string, any>;
	selected_client_company: Record<string, any>;
	statuses: any[];
	status: StatusType | null;
	memberingType: 'Extra' | 'Custom';
	isRecurringSet: boolean;
};

export function CreateCaseFormInner({ modalHandler, hasToShowGuard, formValuesSetter }: Props) {
	const { companyId, caseType, selectedCaseFilter } = useAppState();
	const { userData } = useAuth();
	const { id: userId } = userData;
	const { showNotification } = useNotistack();

	const selectedFilterTemplate = selectedCaseFilter[caseType];

	const departmentRef = useRef(null);

	const { changeUrlParams, query, removeQueryParams } =
		useQueryParams<{ case: number; status: number }>();

	const preDefinedCaseTitle = `${query?.caseTitle} | From Client Case - ${query?.caseId}`;
	const titleRef = useRef(query.caseTitle ? preDefinedCaseTitle : null);

	const [state, setState] = useState<TCreateCaseFormState>({
		department: {},
		data_fields: [],
		data_fields_names: {},
		data_fields_errors: {},
		data_fields_names_persisted: {},
		isDateTimeSet: false,
		client_company: {},
		client_user: {},
		selected_client_company: {},
		statuses: [],
		status: null,
		memberingType: 'Extra',
		isRecurringSet: false
		// title: '',
	});

	const {
		data_fields,
		data_fields_names,
		data_fields_errors,
		status,
		client_company,
		memberingType
	} = state;

	//Sending initial request to check whether there is only client company and only client company user
	useCaseCreateInitialRequests(setState, state);

	const entityName = `AllBusinessCases-${status?.id}-${caseType}-${
		selectedFilterTemplate ? selectedFilterTemplate.id : 'normal'
	}`;

	const { handleLocalDelete } = useEntityForm({
		deleteData: true,
		method: 'delete',
		url: '',
		entity: 'cases',
		name: entityName,
		cb: {
			success: () => {},
			error: () => {},
			finally: () => {}
		}
	});

	const finalizeSupDepValue = (subDepObj: any) => {
		/*
		 * In staff case creating if we select and remove sub department
		 * form sends  {sub_department: {id:null}}. This function just prevents that issue
		 * If getSubDepartmentPayload() fn return {id:null} we return empty {}
		 * */
		const key = Object.keys(subDepObj)[0];
		return isNaN(subDepObj[key]) ? {} : subDepObj;
	};

	return (
		<div>
			<EntityForm.Main
				entity="cases"
				name={entityName}
				url={`/${companyId}/cases/as_business/`}
				method="post"
				primaryKey="id"
				sendAsFormData={false}
				prependData={false}
				appendData={false}
				fields={[
					{
						name: 'title',
						required: true,
						onSubmitKey: '',
						value: query.caseTitle ? preDefinedCaseTitle : ''
					},
					{
						name: 'description',
						onSubmitValue: (value, values) => get(values, 'title'),
						onSubmitKey: ''
					},
					{
						name: 'department',
						required: true,
						onSubmitValue: (value, values) => getDepartmentPayload(values),
						onSubmitKey: '',
						type: 'object'
					},
					{
						name: 'sub_department',
						onSubmitValue: (value, values) =>
							finalizeSupDepValue(getSubDepartmentPayload({ values, departmentRef })),
						onSubmitKey: '',
						type: 'object'
					},
					{
						name: 'data_field',
						// eslint-disable-next-line no-unused-vars
						onSubmitValue: (value, values) =>
							createDataFieldsOnSubmit({ data_fields, data_fields_names }),
						required: Object.values(data_fields_errors).includes(true),
						onSubmitKey: ''
					},
					{
						name: 'priority',
						value: { name: 'Normal', code: 'NORMAL' },
						onSubmitValue: (value) => get(value, 'code'),
						onSubmitKey: '',
						type: 'object'
					},
					{
						name: 'temp_sub',
						value: null,
						onSubmitKey: ''
					},
					{
						name: 'due_date',
						//@ts-ignore
						onSubmitValue: (value) => (state.isDateTimeSet ? dayjs(value).utc().format() : null),
						onSubmitKey: ''
					},
					{
						name: 'client_company',
						onSubmitValue: (value) => (typeof value === 'object' ? get(value, 'id') : companyId),
						value:
							caseType === config.CLIENT
								? !isEmpty(state.client_company)
									? state.client_company
									: null
								: { id: companyId },
						required: true,
						onSubmitKey: '',
						type: 'object'
					},
					{
						name: 'client_user',
						onSubmitValue: (value) => (typeof value === 'object' ? get(value, 'id') : userId),
						value:
							caseType === config.CLIENT
								? !isEmpty(state.client_user)
									? state.client_user
									: null
								: { id: userId },
						required: true,
						onSubmitKey: '',
						type: 'object'
					},
					{
						name: 'assignee_ids',
						onSubmitValue: (value) => [
							...new Set(value && value.length ? value.map((user: any) => user.user.id) : [])
						],
						value: null,
						type: 'object',
						onSubmitKey: ''
					},
					{
						name: 'status',
						value: state.statuses[0],
						onSubmitValue: (value) => get(value, 'id'),
						required: true,
						onSubmitKey: '',
						type: 'object'
					},
					{
						name: 'member_list',
						onSubmitValue: (value, values) => membersListHandler(value, values, memberingType),
						onSubmitKey: '',
						type: 'object'
					},
					{
						name: 'exception_member_list',
						onSubmitValue: (value, values) =>
							exceptionMembersListHandler(value, values, userId, memberingType),
						onSubmitKey: '',
						type: 'object'
					},
					{
						name: 'recurring',
						value: null,
						onSubmitValue: (value) => (state.isRecurringSet ? value : null),
						required: state.isRecurringSet,
						onSubmitKey: '',
						type: 'object'
					},
					{
						name: 'complete_status',
						onSubmitValue: (value) => (state.isDateTimeSet ? get(value, 'id') : null),
						required: state.isDateTimeSet,
						onSubmitKey: '',
						type: 'object'
					},
					{
						name: 'related_to_type',
						value: 'CASE',
						onSubmitKey: ''
					},
					{
						name: 'related_to_id',
						value: query.caseId ? Number(query.caseId) : null,
						onSubmitKey: ''
					}
				]}
				onSuccess={(response, resetForm): any => {
					//Close current chat if open
					removeQueryParams();

					const {
						priority = 'NORMAL',
						status = {},
						id = null,
						assignee_ids,
						department = {},
						sub_department = {}
					} = response;

					/* const itShouldSatisfyFilterTemplate = checkCaseForFiltering({
						priority: priority,
						statusId: status?.id,
						toUser: assignee_ids.includes(userData.id) ? { id: userData.id } : {},
						messageType: '',
						taggedUserIds: [],
						departmentId: department?.id,
						subDepartmentId: sub_department?.id,
						entityId: id
					}); */

					/* CASE: 4187 - new created case should be opened even though any filter is active, after closing the case, 
					unmatched case should be removed from the caselist. 
					TODO: watch any side-effects, i removed  itShouldSatisfyFilterTemplate check*/
					//open new created case
					setTimeout(() => {
						changeUrlParams({ status: status?.id, case: id }, true);
					}, 500);

					const filterQuery = selectedCaseFilter[caseType]?.filter_json?.query_web;

					//remove created case from caselist due to case 2438
					if (
						filterQuery &&
						'department' in filterQuery &&
						!filterQuery.department.includes(response?.department?.id?.toString())
					) {
						handleLocalDelete(response.id);
					}

					modalHandler();
					resetForm();
				}}
				onError={(error, resetForm): any => {
					showNotification({
						message: error?.response?.data?.message,
						variant: 'error'
					});
				}}
			>
				{({ isSubmitting, values, setFieldValue, resetForm, errors }) => {
					if (process.env.NODE_ENV === 'development') {
						console.log('values', values);
						console.log('create case form state', state);
					}

					return (
						<>
							<CreateCaseElements
								values={values}
								errors={errors}
								setFieldValue={setFieldValue}
								resetForm={resetForm}
								isSubmitting={isSubmitting}
								state={state}
								data_fields={data_fields}
								data_fields_names={data_fields_names}
								data_fields_errors={data_fields_errors}
								setState={setState}
								titleRef={titleRef}
								departmentRef={departmentRef}
								client_company={client_company}
								hasToShowGuard={hasToShowGuard}
								formValuesSetter={formValuesSetter}
							/>
						</>
					);
				}}
			</EntityForm.Main>
		</div>
	);
}

export function CreateCaseForm(props: Props) {
	return (
		<ErrorBoundary>
			<CreateCaseFormInner {...props} />
		</ErrorBoundary>
	);
}

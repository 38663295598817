import React, { useCallback, useEffect, useState } from 'react';
import { IconWrapper } from '../../IconWrapper';
import { useRouter, useSearchQuery } from 'shared/hooks';
import { useAppState } from 'shared/state';
import { WhiteModal } from 'shared/components/Elements';
import { BiSearch } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { SearchInput } from 'shared/components/SearchInput';


export function SearchBar() {
	const { t } = useTranslation();
	const { query, setQuery, searchQuery } = useSearchQuery();
	const { setSearchQuery, searchQuery: searchQueryFromState, isAppAvailable } = useAppState();
	const { history, path } = useRouter();
	const [open, setOpen] = useState(false);

	useEffect(() => {
		setSearchQuery(searchQuery);
	}, [searchQuery]);

	//Reset search When we leave Case Messages page
	useEffect(() => {
		if (!searchQueryFromState.length) setQuery('');
	}, [searchQueryFromState]);

	const handleModalOpen = useCallback(() => {
		//reset search input on modal close
		if (query.length) {
			// setQuery(''); //commented it because it is causing exit from search mode on modal close
		}
		setOpen((prev) => !prev);
	}, [query]);

	const handleInput = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (!path.includes('case') && path !== '/') {
				history.push('/case-messages/');
			}
			const regexHash = /(#)/gi;
			const search = e.target.value.replace(regexHash, '');
			setQuery(search);
		},
		[path]
	);

	return (
		<div className="relative flex h-full items-center border-r border-kgrey-border-solid pl-2 pr-2 font-poppins dark:border-white/20 lg:pl-5 lg:pr-5">
			<div className="relative hidden h-[32px] w-[200px] lg:block">
				<SearchInput handleInput={handleInput} query={query} disabled={!isAppAvailable} />
			</div>
			<IconWrapper classes={'lg:hidden dark:text-white'}>
				<BiSearch onClick={() => setOpen(true)} />
			</IconWrapper>
			<WhiteModal
				handleModalOpen={handleModalOpen}
				open={Boolean(open)}
				title={t('search_cases_secondary')}
			>
				<div className="relative h-[32px] w-full">
					<SearchInput handleInput={handleInput} query={query} />
				</div>
			</WhiteModal>
		</div>
	);
}

import React, { useCallback, useMemo, useState } from 'react';
import { CaseStatus, IMessage, ScrollToMsg } from 'shared/interfaces';
import { useMessageFields } from 'shared/hooks/chat-hooks/useMessageFields';
import { AvatarSized } from 'shared/components/Elements';
import clsx from 'clsx';
import Popover from '@material-ui/core/Popover';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { MessageMenu } from './MessageMenu';
import { useComponentVisible, useGetEntity, useWhiteModal } from 'shared/hooks';
import { MessageInfo } from './MessageInfo';
import { useMessageActions } from 'shared/hooks/chat-hooks/useMessageActions';
import { Emojies, ErrorBoundary, Loader } from 'shared/components';
import { TextMsg } from './TextMsg';
import isEqual from 'lodash/isEqual';
import { LikeButtonText } from './LikeButtonText';
import { MdAccessTime, MdDone, MdDoneAll } from 'react-icons/md';
import { BsThreeDots } from 'react-icons/bs';
import { TranslateBtn } from './TranslateBtn';
import { useMessageTranslate } from '../hooks/useMessageTranslate';
import { convertMsgTime } from '../utils';
import { ChangeContentLanguage } from 'pages/UserProfile/components/ChangeContentLanguage';
import VisibilitySensor from 'react-visibility-sensor';
import { useChatContext } from 'shared/contexts';
import { useSelector } from 'react-redux';
import Selectors from 'modules/entity/selectors';
import { useTranslation } from 'react-i18next';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

type Props = {
	message: IMessage;
	MessageView?: React.ReactNode;
	isTextMessage?: boolean;
	setScrollToMsgId?: ScrollToMsg;
	isSameFromUserWithPrev: boolean;
	isSameFromUserWithNext: boolean;
	fileType?: string;
};

const renderReadIcon = (read_time: number | null, messageId: number | undefined) => {
	if (!messageId) return <MdAccessTime />;
	if (!read_time) return <MdDone />;
	if (read_time) return <MdDoneAll />;
};

const useStyles = makeStyles(
	createStyles({
		menuPaper: {
			borderRadius: '12px'
		}
	})
);

function areEqual(prev: any, next: any) {
	const id = prev.message?.id === next.message?.id;
	const settings = isEqual(prev.message.settings, next.message.settings);
	const message_id = prev.message?.message_id === next.message?.message_id;
	const is_translation_shown =
		prev.message?.is_translation_shown === next.message?.is_translation_shown;
	const translated_text = prev.message?.translated_text === next.message?.translated_text;
	const created_date = prev.message.created_date === next.message.created_date;
	const read_time = prev.message.read_time === next.message.read_time;
	const text = prev.message.text === next.message.text;
	const file_type = prev.message.file_type === next.message.file_type;
	const custom_uuid = prev.message.custom_uuid === next.message.custom_uuid;
	const edited = prev.message.edited === next.message.edited;
	const from_user = isEqual(prev.message.from_user, next.message.from_user);
	const to_user = isEqual(prev.message.to_user, next.message.to_user);
	const replied_to = isEqual(prev.message.replied_to, next.message.replied_to);
	const reacted_users = isEqual(prev.message.reacted_users, next.message.reacted_users);
	const isTextMessage = prev.isTextMessage === next.isTextMessage;
	const setScrollToMsgId = prev.setScrollToMsgId === next.setScrollToMsgId;
	const messageView = prev.MessageView === next.MessageView;
	const isSameWithPrev = prev.isSameFromUserWithPrev === next.isSameFromUserWithPrev;
	const isSameWithNext = prev.isSameFromUserWithNext === next.isSameFromUserWithNext;
	const isDeleted = prev.message?.is_deleted === next.message?.is_deleted;
	const scheduledTo = prev.message?.scheduled_to === next.message?.scheduled_to;

	return (
		id &&
		message_id &&
		created_date &&
		read_time &&
		text &&
		file_type &&
		custom_uuid &&
		edited &&
		from_user &&
		to_user &&
		replied_to &&
		reacted_users &&
		isTextMessage &&
		setScrollToMsgId &&
		messageView &&
		isSameWithPrev &&
		isSameWithNext &&
		settings &&
		is_translation_shown &&
		translated_text &&
		isDeleted &&
		scheduledTo
	);
}

const getAll = Selectors.getAll();

export const MessageLayoutInner = React.memo(function MessageLayout({
	message,
	MessageView,
	isTextMessage = false,
	setScrollToMsgId = () => {},
	isSameFromUserWithPrev,
	isSameFromUserWithNext,
	fileType
}: Props) {
	const {
		is_deleted,
		from_user,
		isOwnMsg,
		read_time,
		messageId,
		text,
		file_type,
		custom_uuid,
		edited,
		replied_to,
		reacted_users,
		file,
		sent_time,
		is_translation_shown,
		scheduled_to
	} = useMessageFields(message);
	const { entityType, entityName, virtuosoRef } = useChatContext();
	const classes = useStyles();
	const { open, modalHandler, WhiteModal } = useWhiteModal();
	const { open: openLanguageSelectModal, modalHandler: toggleLanguageSelectModal } =
		useWhiteModal();
	const { t } = useTranslation();
	const { languageDirection, isRTL } = useTranslationHelpers();

	const messages = useSelector((state) =>
		getAll(state, {
			entity: `${entityType}Messages`,
			name: entityName
		})
	)?.items as IMessage[];

	const [componentCollideWithInput, setComponentCollideWithInput] = useState(false);

	const {
		ref: emojiRef,
		isComponentVisible,
		setIsComponentVisible: toggleReactionPicker
	} = useComponentVisible(false);
	// const { setCoords, coords } = useChatInputContext();
	const {
		anchorEl,
		contextMenu,
		handleContextClick,
		handleContextClose,
		handleClick,
		handleClose,
		renderReacted,
		coords,
		getCoordinatesHandler
	} = useMessageActions(toggleReactionPicker, messageId);

	const { handleTranslate, isTranslationFetched } = useMessageTranslate({
		text,
		translated_text: message.translated_text,
		original_text: message.original_text,
		custom_uuid,
		message_id: message.message_id,
		id: message.id,
		is_translation_shown: is_translation_shown
	});

	const infoModalHandler = useCallback(() => {
		modalHandler();
	}, []);

	const languageSelectModalHandler = useCallback(() => {
		toggleLanguageSelectModal();
	}, []);

	const toggleEmojiPicker = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			getCoordinatesHandler(e);
			toggleReactionPicker(true);

			const messageIndex = (messages as IMessage[])?.findIndex(
				(message) => (message?.id ?? message?.message_id) === messageId
			);

			//fix: 3725 - emoji picker should be shown by scrolling to the message even if emoji toggle button is in between input
			if (componentCollideWithInput && messageIndex !== -1) {
				setTimeout(() => {
					virtuosoRef?.current?.scrollToIndex({
						align: 'end',
						index: messageIndex,
						offset: 50
					});
				}, 0);
			}
		},
		[messageId, componentCollideWithInput, messages]
	);

	const cachedContextPopover = useMemo(() => {
		return (
			<>
				<Popover
					keepMounted
					open={contextMenu.mouseY !== null && !anchorEl}
					onClose={handleContextClose}
					anchorReference="anchorPosition"
					classes={{ paper: classes.menuPaper }}
					anchorPosition={
						contextMenu.mouseY !== null && contextMenu.mouseX !== null
							? { top: contextMenu.mouseY, left: contextMenu.mouseX }
							: undefined
					}
				>
					<MessageMenu
						languageSelectModalHandler={languageSelectModalHandler}
						messageId={messageId}
						handleClose={handleContextClose}
						text={text}
						fileType={file_type}
						fromUserFirstName={from_user?.first_name}
						fromUserLastName={from_user?.last_name}
						custom_uuid={custom_uuid}
						isOwnMsg={isOwnMsg}
						repliedToId={replied_to ? replied_to.id : null}
						infoModalHandler={infoModalHandler}
						fileUrl={file && file.url}
						original_text={message.original_text}
						isDeleted={is_deleted}
						scheduled_to={scheduled_to}
					/>
				</Popover>
			</>
		);
	}, [
		contextMenu,
		handleContextClose,
		messageId,
		text,
		file_type,
		from_user?.first_name,
		custom_uuid,
		replied_to,
		infoModalHandler,
		languageSelectModalHandler
	]);

	const cachedBtnPopover = useMemo(() => {
		return (
			<>
				<Popover
					id="message-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					classes={{ paper: classes.menuPaper }}
					onClose={handleClose}
				>
					<MessageMenu
						languageSelectModalHandler={languageSelectModalHandler}
						messageId={messageId}
						handleClose={handleClose}
						text={text}
						fileType={file_type}
						fromUserFirstName={from_user?.first_name}
						fromUserLastName={from_user?.last_name}
						custom_uuid={custom_uuid}
						isOwnMsg={isOwnMsg}
						repliedToId={replied_to ? replied_to.id : null}
						infoModalHandler={infoModalHandler}
						fileUrl={file && file.url}
						original_text={message.original_text}
						isDeleted={is_deleted}
						scheduled_to={scheduled_to}
					/>
				</Popover>
			</>
		);
	}, [
		anchorEl,
		handleClose,
		messageId,
		text,
		file_type,
		from_user?.first_name,
		custom_uuid,
		replied_to,
		infoModalHandler,
		languageSelectModalHandler
	]);

	const cachedPickers = useMemo(() => {
		return (
			<>
				{!is_deleted && !scheduled_to ? (
					<Emojies.EPicker
						messageId={messageId}
						ref={emojiRef}
						custom_uuid={custom_uuid}
						isComponentVisible={isComponentVisible}
						renderReacted={renderReacted}
						coordinates={coords}
						isOwnMsg={isOwnMsg}
						reactedUsers={reacted_users}
						classnames={file_type === 'LOCATION' ? 'z-[1001]' : 'z-20'}
					/>
				) : null}
			</>
		);
	}, [
		custom_uuid,
		isComponentVisible,
		renderReacted,
		coords,
		isOwnMsg,
		reacted_users,
		file_type,
		toggleEmojiPicker,
		is_deleted,
		scheduled_to
	]);

	const cachedAvatar = useMemo(() => {
		return (
			<div className={clsx('avatar h-9 w-9')}>
				<AvatarSized
					size={36}
					smallSize={36}
					className={''}
					letter={`${from_user?.first_name?.[0]}${from_user?.last_name?.[0]}`}
					alt={`${from_user?.first_name} ${from_user?.last_name}`}
					src={
						from_user?.avatar?.thumbnail_150 ||
						from_user?.avatar?.thumbnail ||
						from_user?.avatar?.url
					}
					color={from_user?.color}
				/>
			</div>
		);
	}, [from_user]);

	const cachedThreeDots = useMemo(() => {
		return (
			<div
				className={clsx(
					'cursor-pointer text-2xl text-kgrey transition hover:text-primary dark:text-white',
					{
						'order-first mr-3': isOwnMsg,
						'ml-3 self-center': !isOwnMsg
					}
				)}
				aria-controls="message-menu"
				aria-haspopup="true"
				onMouseDown={handleClick}
			>
				<BsThreeDots />
			</div>
		);
	}, [handleClick, isOwnMsg]);

	return (
		<VisibilitySensor
			onChange={(isVisible) => {
				setComponentCollideWithInput(!isVisible);
			}}
			offset={{ bottom: 80 }}
		>
			<>
				{contextMenu.mouseY !== null ? cachedContextPopover : null}
				<div
					className={clsx('flex flex-col ', {
						'items-start': !isOwnMsg,
						'items-end': isOwnMsg
					})}
					onContextMenu={fileType !== 'IMAGE' ? handleContextClick : () => {}}
				>
					<div
						className={clsx('message-wrapper flex max-w-[70%] flex-col', {
							'items-start': !isOwnMsg,
							'items-end': isOwnMsg
						})}
					>
						<div
							className={clsx('message-content-wrapper relative flex max-w-full', {
								'items-start justify-start': !isOwnMsg,
								'items-center justify-end pr-3': isOwnMsg
							})}
						>
							{!isOwnMsg ? (
								<div
									className={clsx('avatar message-avatar h-9 w-9', {
										'opacity-0': isSameFromUserWithNext
									})}
								>
									{cachedAvatar}
								</div>
							) : null}
							<div
								className={clsx('message-content relative max-w-full', {
									'ml-6': !isOwnMsg
								})}
							>
								{!isOwnMsg && (
									<div
										className={clsx('message-sender-name mb-1 text-xs font-medium text-primary', {
											'opacity-0': isSameFromUserWithPrev
										})}
									>{`${from_user?.first_name ?? ''} ${from_user?.last_name ?? ''}`}</div>
								)}

								{isTextMessage ? (
									<div className="relative">
										{!isTranslationFetched && <Loader tiled />}
										<TextMsg
											message={message}
											setScrollToMsgId={setScrollToMsgId}
											toggleEmojiPicker={toggleEmojiPicker}
										/>
									</div>
								) : (
									MessageView
								)}
								{cachedPickers}
							</div>
							{messageId ? cachedThreeDots : null}
							{Boolean(anchorEl) ? cachedBtnPopover : null}
							{isOwnMsg ? (
								<div className="message-delivery-icon ml-3">
									<span className={'block h-4 w-4 text-primary'}>
										{renderReadIcon(read_time, messageId)}
									</span>
								</div>
							) : null}
						</div>
						<div
							className={clsx(
								'meta-wrapper mt-1 flex w-full  items-end text-xs text-kgrey-msg/70 dark:text-white',
								{
									'mr-7 pl-8': isOwnMsg,
									'ml-16 ': !isOwnMsg,
									'ml-0  justify-end': file_type === 'FILE'
								}
							)}
						>
							<div dir={languageDirection} className={clsx('date')}>
								{scheduled_to
									? t('scheduled_for_date', { date: convertMsgTime(scheduled_to) })
									: convertMsgTime(sent_time)}
							</div>
							{!is_deleted && !scheduled_to ? (
								<LikeButtonText toggleEmojiPicker={toggleEmojiPicker} isOwnMsg={isOwnMsg} />
							) : null}

							{edited ? (
								<div
									className={clsx('italic text-primary', {
										'order-first mx-4': !isOwnMsg,
										'mx-4': isOwnMsg
									})}
								>
									{t('edited')}
								</div>
							) : null}
							{!is_deleted && !scheduled_to ? (
								<TranslateBtn
									isOwnMsg={isOwnMsg}
									isTextMessage={isTextMessage}
									clickHandler={handleTranslate}
									isTranslated={!!is_translation_shown}
								/>
							) : null}
						</div>
					</div>
				</div>
				<WhiteModal handleModalOpen={modalHandler} open={open}>
					<MessageInfo messageId={messageId} />
				</WhiteModal>
				<WhiteModal handleModalOpen={toggleLanguageSelectModal} open={openLanguageSelectModal}>
					<ChangeContentLanguage modalHandler={toggleLanguageSelectModal} />
				</WhiteModal>
			</>
		</VisibilitySensor>
	);
},
areEqual);

export function MessageLayout(props: Props) {
	return (
		<ErrorBoundary isCompact>
			<MessageLayoutInner {...props} />
		</ErrorBoundary>
	);
}
